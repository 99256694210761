import React, { useEffect, useState } from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

export const DropdownHelper = (props) => {

    const [datalist, setDatalist] = useState([]);
    // const [selectedValue, setSelectedValue] = useState('')
    // const [noneFieldRequired, setNoneFieldRequired] = useState(false)
    // const [selectedIndex, setSelectedIndex] = useState('')

    // const [componentLoading, setComponentLoading] = useState(false)


    useEffect(() => {
        if (props.datalist !== undefined && props.datalist != null && Array.isArray(props.datalist)) {
            setDatalist(props.datalist);
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (props.datalist !== undefined && props.datalist != null && Array.isArray(props.datalist)) {
            setDatalist(props.datalist);
        }
    }, [props.datalist.length]) // eslint-disable-line react-hooks/exhaustive-deps

    const updateSelectedData = async (event) => {
        await props.updateSelectedData(event.target.value);
        if(props.updateDatatable !== undefined && props.updateDatatable != null){
            props.updateDatatable(props.dropdownHeader ,event.target.value);

        }

    }

    return (
        <React.Fragment>
            <div className={(props.parentDivClassName !== undefined && props.parentDivClassName != null) ?`droupdown__helper ${props.parentDivClassName}` : "droupdown__helper"}>
                <FormControl className={`second__step__area__select second__step__area__select__100 ${(props.from === undefined || props.from == null||props.from !=='add-deal') ?'assign__stage_select_wrapper' : ''}`}>
                    <Select
                    //  className="contact__middle__inbox__select__innner"
                        value={props.selectedValue} 
                        onChange={updateSelectedData}
                        className={(props.className !== undefined && props.undefined != null) ? props.className : ''}
                        displayEmpty
                        inputProps={{ 'aria-label': (props.ariaLabel !== undefined && props.ariaLabel != null) ? props.ariaLabel : 'Without label' }}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left"
                            },
                            getContentAnchorEl: null,
                            classes: { paper: `${props.parentDivClassName}` }
                        }}
                    >
                        {props.noneFieldRequired !== undefined && props.noneFieldRequired != null && props.noneFieldRequired &&
                            <MenuItem value="" index="">
                                <em>{(props.noneFieldTitle !== undefined && props.noneFieldTitle != null) ? props.noneFieldTitle : 'None'}</em>
                            </MenuItem>
                        }
                        {Array.isArray(datalist) && props.mapping !=null && (props.mapping.id === undefined || props.mapping.id == null) &&
                            datalist.map((data, index) => (
                                <MenuItem
                                    value={data.id} key={`dropdownHelper${index}${data.id}`}
                                    className={`dropdownhelper-menuitem-class d-flex ${(props.menuItemClassName !== undefined && props.menuItemClassName != null) ? props.menuItemClassName : ''}`}
                                >{data[props.mapping.title]}</MenuItem>

                            ))
                        }
                        {Array.isArray(datalist) && props.mapping !=null && props.mapping.id !== undefined && props.mapping.id != null &&
                            datalist.map((data, index) => (
                                <MenuItem
                                    value={data[props.mapping.id]} key={`dropdownHelper${index}${data[props.mapping.id]}`}
                                    className={`dropdownhelper-menuitem-class d-flex ${(props.menuItemClassName !== undefined && props.menuItemClassName != null) ? props.menuItemClassName : ''}`}
                                >{data[props.mapping.title]}</MenuItem>

                            ))
                        }
                        {Array.isArray(datalist) && props.mapping ==null &&
                            datalist.map((data, index) => (
                                <MenuItem
                                    value={data} key={`dropdownHelper${index}`}
                                    className={`dropdownhelper-menuitem-class d-flex ${(props.menuItemClassName !== undefined && props.menuItemClassName != null) ? props.menuItemClassName : ''}`}
                                >{data}</MenuItem>

                            ))
                        }
                        {/* {props.mapping.id == 'key' &&

                            Object.keys(datalist).map((x,y) => <MenuItem key={x} value={x}>{datalist[x]}</MenuItem>)
                        } */}
                    </Select>
                </FormControl>
            </div>
        </React.Fragment>
    )
}

import React, { useEffect, useState } from "react"
import { DropdownHelper } from "../../common/DropdownHelper";
import Utils from "../../../helpers/Utils";
import BootstrapTooltip from "../../globals/BootstrapTooltip";
import HelpIcon from "@material-ui/icons/Help";
let registerStadtardPdf = `${process.env.REACT_APP_CDN_LINK}asset/images/releases/1.0.0/standard-lowvolume.pdf`;
const JOB_POSITION_ARRAY = [
    {label: 'Director', value: 'Director'},
    {label: 'GM', value: 'GM'},
    {label: 'VP', value: 'VP'},
    {label: 'CEO', value: 'CEO'},
    {label: 'CFO', value: 'CFO'},
    {label: 'General Counsel', value: 'General_Counsel'},
    {label: 'Other', value: 'Other'}
];

const PointContact = ({data1, data2, handleUpdateData, handleStep, handleBack}) => {

    const [completeAuthRepOne, setCompleteAuthRepOne] = useState(false)
    const [completeAuthRepTwo, setCompleteAuthRepTwo] = useState(false)
    const [errorMessage, setErrorMessage] = useState({
        email1: "",
        mobileNumber1: "",
        email2: "",
        mobileNumber2: ""
    })

    const [authRepOne, setAuthRepOne] = useState({
        'rep_user_1_business_title' : data1.rep_user_1_business_title,
        'rep_user_1_email' : data1.rep_user_1_email,
        'rep_user_1_first_name' : data1.rep_user_1_first_name,
        'rep_user_1_job_position' : data1.rep_user_1_job_position,
        'rep_user_1_last_name' : data1.rep_user_1_last_name,
        'rep_user_1_phone_number' : data1.rep_user_1_phone_number,
        'auth_rep_1': data1.auth_rep_1
    })

    const [authRepTwo, setAuthRepTwo] = useState({
        'rep_user_2_business_title' : data2.rep_user_2_business_title,
        'rep_user_2_email' : data2.rep_user_2_email,
        'rep_user_2_first_name' : data2.rep_user_2_first_name,
        'rep_user_2_job_position' : data2.rep_user_2_job_position,
        'rep_user_2_last_name' : data2.rep_user_2_last_name,
        'rep_user_2_phone_number' : data2.rep_user_2_phone_number,
        'auth_rep_2' : data2.auth_rep_2
    })
    const [isSecondRepresentative, setIsSecondRepresentative] = useState("no");

    const handleSecondRepresentative = (event) => {
        const isRequired = event.target.value;
        setIsSecondRepresentative(isRequired);
        handleFieldTwo(isRequired === "yes", "auth_rep_2");
    };

    useEffect(() => {
        let complete = true;
        for (const [ value_each] of Object.entries(authRepOne)) {
            if(!complete){
                break
            }
            if(typeof value_each === "string"){
                if(value_each.trim() === ""){
                    complete = false;
                }
            }
        }

        if((errorMessage && errorMessage.email1 && errorMessage.email1.trim() !== "") || (errorMessage && errorMessage.mobileNumber1 && errorMessage.mobileNumber1.trim() !== "")){
            complete = false;
        }

        setCompleteAuthRepOne(complete)
    }, [authRepOne]) // eslint-disable-line react-hooks/exhaustive-deps 

    useEffect(() => {
        let complete = true;
        if(!authRepTwo.auth_rep_2){
            setCompleteAuthRepTwo(complete)
        }
        else{
            for (const [value_each] of Object.entries(authRepTwo)) {
                if(!complete){
                    break
                }
                if(typeof value_each === "string"){
                    if(value_each.trim() === ""){
                        complete = false;
                    }
                }
            }

            if((errorMessage && errorMessage.email2 && errorMessage.email2.trim() !== "") || (errorMessage && errorMessage.mobileNumber2 && errorMessage.mobileNumber2.trim() !== "")){
                complete = false;
            }

            setCompleteAuthRepTwo(complete)
        }
    }, [authRepTwo]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleFieldOne = (value, field) => {
        if(field === 'rep_user_1_phone_number'){
            value =  value.replace(/[^0-9]/g, '')
            if(value.length > 11){
                return
            }
        }

        if (field === 'rep_user_1_email'){
            // if (!Utils.isValidEmail(value)){
            if (!ValidateEmail(value)){
                setErrorMessage({
                   ...errorMessage, email1: "Please provide a valid email address"
                });
            }else {
                setErrorMessage({
                    ...errorMessage, email1: ""
                });
            }
        }

        if (field === 'rep_user_1_phone_number'){
            if (!Utils.isValidMobileNumber(value)){
                setErrorMessage({
                    ...errorMessage, mobileNumber1: "Please provide a valid mobile number"
                });
            }else {
                setErrorMessage({
                    ...errorMessage, mobileNumber1: ""
                });
            }
        }

        setAuthRepOne({...authRepOne, [field] : value.trim()})
        handleUpdateData('pointRep1', field, value.trim())
    }
    
    const handleFieldTwo = (value, field) => {
        if(field === 'rep_user_2_phone_number'){
            value =  value.replace(/[^0-9]/g, '')
            if(value.length > 11){
                return
            }
        }
        if(field === "auth_rep_2"){
            setAuthRepTwo({...authRepTwo, [field] : value})
            handleUpdateData('pointRep2', field, value)
        }
        else{
            setAuthRepTwo({...authRepTwo, [field] : value.trim()})
            handleUpdateData('pointRep2', field, value.trim())
        }

        if (field === 'rep_user_2_email'){
            if (!Utils.isValidEmail(value)){
                setErrorMessage({
                    ...errorMessage, email2: "Please provide a valid email address"
                });
            }else {
                setErrorMessage({
                    ...errorMessage, email2: ""
                });
            }
        }

        if (field === 'rep_user_2_phone_number'){
            if (!Utils.isValidMobileNumber(value)){
                setErrorMessage({
                    ...errorMessage, mobileNumber2: "Please provide a valid mobile number"
                });
            }else {
                setErrorMessage({
                    ...errorMessage, mobileNumber2: ""
                });
            }
        }
    }

    const handleNextClick = () => {
        if(!authRepTwo.auth_rep_2){
            if(!completeAuthRepOne){
                if(window.showNotification!== undefined){
                    window.showNotification("ERROR", "Fill up the form correctly");
                }
                return
            }
            handleStep()
        }
        else{
            if(!completeAuthRepOne || !completeAuthRepTwo){
                if(window.showNotification!== undefined){
                    window.showNotification("ERROR", "Fill up the form correctly");
                }
                return
            }
            handleStep()
        }
    }

    const ValidateEmail = (mail) =>
    {
        //eslint-disable-next-line
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
        {
            return (true)
        }
        return (false)
    }

    return(
        <div className="twilio___migration__point_step twili__migration___container">
            <div className="row" style={{ position: "relative" }}>
                <div className="col s12">
                    <div style={{ position: "absolute", right: "12px", border: "1px solid #dd1616", borderRadius: "4px", padding: "2px"}}>
                        <BootstrapTooltip arrow title={"Standard A2P Registration"}>
                            <a href={registerStadtardPdf} target="_blank" rel="noreferrer" className="ml-2" style={{ display: "flex", alignItems: "center", fontSize: "14px", color: "#dd1616", gap: "2px" }}>
                                Get Help
                                <HelpIcon style={{ color: "#dd1616" }}/>
                            </a>
                        </BootstrapTooltip>
                    </div>
                </div>
            </div>
            <h5>Authorized Representative 1</h5>
            <p className="point_contact_helper_text">This should be someone who can be contacted about this customer profile. They’ll only be contacted if there are urgent issues.</p>

            <div className="row">
                <div className="col s12">
                    <h6 className="twilio__label__v2 text-dark-blue m-0 text-left mb-2">First Name *</h6>
                    <input 
                        value={authRepOne.rep_user_1_first_name} 
                        onChange={ (e) => handleFieldOne(e.target.value, 'rep_user_1_first_name')} 
                        type="text" 
                        placeholder="Enter first name"
                        className="modal_bordered_text_field radius-5 white"
                    />
                </div>
            </div>
            <div className="row">
                <div className="col s12">
                    <h6 className="twilio__label__v2 text-dark-blue m-0 text-left mb-2">Last Name *</h6>
                    <input 
                        value={authRepOne.rep_user_1_last_name} 
                        onChange={ (e) => handleFieldOne(e.target.value, 'rep_user_1_last_name')} 
                        type="text" 
                        placeholder="Enter last name"
                        className="modal_bordered_text_field radius-5 white"
                    />
                </div>
            </div>

            <div className="row">
                <div className="col s12">
                    <h6 className="twilio__label__v2 text-dark-blue m-0 text-left mb-2">Business Title *</h6>
                    <input 
                        value={authRepOne.rep_user_1_business_title} 
                        onChange={ (e) => handleFieldOne(e.target.value, 'rep_user_1_business_title')} 
                        type="text" 
                        placeholder="Enter business title"
                        className="modal_bordered_text_field radius-5 white"
                    />
                </div>
            </div>

            <div className="row twilio__migration__droup__down">
                <div className="col s12">
                    <h6 className="twilio__label__v2 text-dark-blue m-0 text-left mb-2">Job Position *</h6>
                    <DropdownHelper
                        datalist={JOB_POSITION_ARRAY}
                        noneFieldRequired={true}
                        noneFieldTitle="Select job position"
                        mapping={{ title: "label" ,id: 'value'}}
                        menuItemClassName=""
                        // updateSelectedData={(value) => handleField(value, 'identifierType')}
                        updateSelectedData={(value) => handleFieldOne(value, 'rep_user_1_job_position')}
                        selectedValue={authRepOne.rep_user_1_job_position}
                        dropdownHeader={'ownerChange'}
                        parentDivClassName="ppc__select_owner actvity-modal-dropdown-time"
                    />
                </div>
            </div>

            <div className="row">
                <div className="col s12">
                    <h6 className="twilio__label__v2 text-dark-blue m-0 text-left mb-2">Email *</h6>
                    <input 
                        value={authRepOne.rep_user_1_email} 
                        onChange={ (e) => handleFieldOne(e.target.value, 'rep_user_1_email')} 
                        type="text" 
                        placeholder="Enter email"
                        className="modal_bordered_text_field radius-5 white"
                    />
                    {
                        errorMessage && errorMessage.email1 && (
                            <p className="twilio__migration__hints" style={{ color: "red" }}><span>{errorMessage.email1}</span></p>
                        )
                    }
                </div>
            </div>

            <div className="row">
                <div className="col s12">
                    <h6 className="twilio__label__v2 text-dark-blue m-0 text-left mb-2">Phone Number *</h6>
                    <input 
                        value={authRepOne.rep_user_1_phone_number} 
                        onChange={ (e) => handleFieldOne(e.target.value, 'rep_user_1_phone_number')} 
                        type="text" 
                        placeholder="Enter phone number"
                        className="modal_bordered_text_field radius-5 white"
                    />
                    {
                        errorMessage && errorMessage.mobileNumber1 && (
                            <p className="twilio__migration__hints" style={{ color: "red" }}><span>{errorMessage.mobileNumber1}</span></p>
                        )
                    }
                </div>
            </div>
            <div className="row">
                <div className="col s12">
                    <h6 className="twilio__label__v2 text-dark-blue m-0 text-left">Do you want to add another authorized representative ? (Optional)</h6>
                    <span className="point_contact_helper_text">This authorized representative will potentially be contacted if the first one does not respond.</span>
                    <div className="twilio_add_another_auth_radio_wr">
                        <label>
                            <input className="with-gap" name="allow_second" onChange={handleSecondRepresentative} value="yes" type="radio" checked={isSecondRepresentative === "yes"}/>
                            <span className="twilio_add_another_auth_label" style={{ color: "#133159" }}>Yes</span>
                        </label>
                        <label>
                            <input className="with-gap" name="allow_second" onChange={handleSecondRepresentative} value="no" type="radio" checked={isSecondRepresentative === "no"}/>
                            <span className="twilio_add_another_auth_label" style={{ color: "#133159" }}>No</span>
                        </label>
                    </div>
                </div>
            </div>
            {isSecondRepresentative === "yes" &&
            <>
            <div className="representative__second">
                <h5>Authorized Representative 2</h5>
                {/*<div className="representative__second__btn" onClick={() => handleFieldTwo(!authRepTwo.auth_rep_2, 'auth_rep_2')}>{authRepTwo.auth_rep_2 ? "Skip" : "Fill up"} Authorized Representative 2 setting</div>*/}
            </div>
            <div className="row">
                <div className="col s12">
                    <h6 className="twilio__label__v2 text-dark-blue m-0 text-left mb-2">First Name</h6>
                    <input 
                        value={authRepTwo.rep_user_2_first_name} 
                        onChange={ (e) => handleFieldTwo(e.target.value, 'rep_user_2_first_name')} 
                        type="text" 
                        placeholder="Enter first name"
                        className="modal_bordered_text_field radius-5 white"
                    />
                </div>
            </div>

            <div className="row">
                <div className="col s12">
                    <h6 className="twilio__label__v2 text-dark-blue m-0 text-left mb-2">Last Name</h6>
                    <input 
                        value={authRepTwo.rep_user_2_last_name} 
                        onChange={ (e) => handleFieldTwo(e.target.value, 'rep_user_2_last_name')} 
                        type="text" 
                        placeholder="Enter last name"
                        className="modal_bordered_text_field radius-5 white"
                    />
                </div>
            </div>

            <div className="row">
                <div className="col s12">
                    <h6 className="twilio__label__v2 text-dark-blue m-0 text-left mb-2">Business Title</h6>
                    <input 
                        value={authRepTwo.rep_user_2_business_title} 
                        onChange={ (e) => handleFieldTwo(e.target.value, 'rep_user_2_business_title')} 
                        type="text" 
                        placeholder="Enter business title"
                        className="modal_bordered_text_field radius-5 white"
                    />
                </div>
            </div>

            <div className="row twilio__migration__droup__down">
                <div className="col s12">
                    <h6 className="twilio__label__v2 text-dark-blue m-0 text-left mb-2">Job Position</h6>
                    <DropdownHelper
                        datalist={JOB_POSITION_ARRAY}
                        noneFieldRequired={true}
                        noneFieldTitle="Select job position"
                        mapping={{ title: "label" ,id: 'value'}}
                        menuItemClassName=""
                        updateSelectedData={(value) => handleFieldTwo(value, 'rep_user_2_job_position')}
                        selectedValue={authRepTwo.rep_user_2_job_position}
                        dropdownHeader={'ownerChange'}
                        parentDivClassName="ppc__select_owner actvity-modal-dropdown-time"
                    />
                </div>
            </div>

            <div className="row">
                <div className="col s12">
                    <h6 className="twilio__label__v2 text-dark-blue m-0 text-left mb-2">Email</h6>
                    <input 
                        value={authRepTwo.rep_user_2_email} 
                        onChange={ (e) => handleFieldTwo(e.target.value, 'rep_user_2_email')} 
                        type="text" 
                        placeholder="Enter email"
                        className="modal_bordered_text_field radius-5 white"
                    />
                    {
                        errorMessage && errorMessage.email2 && (
                            <p className="twilio__migration__hints" style={{ color: "red" }}><span>{errorMessage.email2}</span></p>
                        )
                    }
                </div>
            </div>

            <div className="row">
                <div className="col s12">
                    <h6 className="twilio__label__v2 text-dark-blue m-0 text-left mb-2">Phone Number</h6>
                    <input 
                        value={authRepTwo.rep_user_2_phone_number} 
                        onChange={ (e) => handleFieldTwo(e.target.value, 'rep_user_2_phone_number')} 
                        type="text" 
                        placeholder="Enter phone number"
                        className="modal_bordered_text_field radius-5 white"
                    />
                    {
                        errorMessage && errorMessage.mobileNumber2 && (
                            <p className="twilio__migration__hints" style={{ color: "red" }}><span>{errorMessage.mobileNumber2}</span></p>
                        )
                    }
                </div>
            </div>

            </>
            }

            <div className="row">
                <div 
                    className="col s12 twilio__migration__submit__btn" 
                    style={(!completeAuthRepOne || !completeAuthRepTwo) ? {'background': '#9e9e9e', 'cursor': 'not-allowed'} : {}} 
                    onClick={handleNextClick}
                >
                    Next
                </div>

                <div 
                    className="col s12 twilio__migration__submit__btn"  
                    onClick={handleBack}
                >
                    Back
                </div>
            </div>
            

        </div>
    )
}
export default PointContact;

export const fetchAppointmentCountReducers = {
    fetchAppointmentCountStart: (state, action) => {
        state.loadingAppointmentCount = true;
        if (action.payload.userFilter) {
            state.totalAppointmentCountFilterType = action.payload.userFilter
        }
        if (action.payload.schedule) {
            state.appointmentSchedule = action.payload.schedule
        }
    },
    fetchAppointmentCountSuccess: (state, action) => {

        state.loadingAppointmentCount = false;
        state.totalAppointmentCount = action.payload.count ?? 0;
    },
    fetchAppointmentCountFail: (state, action) => {
        state.loadingAppointmentCount = false;
        state.error = action.payload;
    },

}

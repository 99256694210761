import { all } from "redux-saga/effects";
import dashboardMiddleware from "./dashboardMiddleware";
import { watchDashboardSaga } from "../reducers/Insurance-dashboard/insuranceDashboardSaga";

export default function* rootMiddleware() {
    yield all(
        [
            watchDashboardSaga(),
            dashboardMiddleware()
        ]
    )
}

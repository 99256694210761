import React from "react";
import { Skeleton } from "@material-ui/lab";

const TabContentSkeleton = () => {
  return (
      <div style={{width: "100%", display: "flex", flexDirection: "column"}}>
        <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "6px",
              padding: "16px",
              marginBottom: "40px"
            }}
        >
          <Skeleton
              variant="rect"
              width={150}
              height={90}
              style={{borderRadius: "6px"}}
          />
          <Skeleton variant="text" width={450} height={30}/>
          <Skeleton variant="text" width={350} height={20}/>
          <Skeleton variant="text" width={250} height={20}/>
        </div>
        <Skeleton variant="text" width={"350px"} height={30} style={{ marginBottom: "24px" }}/>
        {Array.from({length: 5}).map((_, index) => (
            <div
                key={index}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  border: "1px solid #3e50f73d",
                  padding: "16px",
                  borderRadius: "6px",
                }}
            >
              <div style={{display: "flex", alignItems: "center", gap: "12px"}}>
                <Skeleton
                    variant="rect"
                    width={"65px"}
                    height={"65px"}
                    style={{borderRadius: "4px"}}
                />
                <div
                    style={{display: "flex", flexDirection: "column", gap: "2px"}}
                >
                  <Skeleton variant="text" width={"150px"} height={20}/>
                  <Skeleton variant="text" width={"100px"} height={20}/>
                  <Skeleton variant="text" width={"250px"} height={20}/>
                </div>
              </div>
              <Skeleton variant="text" width={"140px"} height={"70px"}/>
            </div>
        ))}
      </div>
  );
};

export default TabContentSkeleton;

import React from "react";
import {
    Container,
    styled
} from "@material-ui/core";
import GetStartedTabs from "./GetStartedTabs";
import GetStartedTabContent from "./GetStartedTabContent";


const GetStartedWrapper = styled(Container)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
    gap: 24,
  },
  marginBottom: 40
}));

const GetStarted = () => {
  return (
    <GetStartedWrapper maxWidth="lg">
      <GetStartedTabs />
      <GetStartedTabContent />
    </GetStartedWrapper>
  );
};

export default GetStarted;

import React, { Fragment } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import Title from "../../common/Title";
import SubTitle from "../../common/SubTitle";
import BasicSelect from "../../../common/BasicSelect";
import BasicTab from "../../common/BasicTab";
import { DASHBOARD_GOAL_TABS } from "../../../../constants/CoreConstants";
import GoalList from "./GoalList";
import GoalChart from "./GoalChart";

const useStyles = makeStyles((theme) => ({
  dailyGoalWrapper: {
    padding: 24,
    backgroundColor: "#ffffff",
    border: "1px solid #E9EAEC",
    borderRadius: 12,

    "& .header": {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: 22,
    },

    "& .filters": {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      flexWrap: "wrap",
      gap: 8,
      pointerEvents: 'none',

      "& .userSelect": {
        width: 340,
      },
    },
  },
}));

const DailyGoal = () => {
  const classes = useStyles();

  return (
    <div className={classes.dailyGoalWrapper}>
      <Fragment>
        <div className="header">
          <div className="greetings">
            <Title text={`Hi User`} />
            <SubTitle text="Here's your agency's goal for today" />
          </div>

          <div className="filters">
            <BasicSelect
              name="pipeline"
              options={[{ value: 1, label: "Calls" }]}
              mapping={{
                label: "name",
                value: "id",
              }}
              className="userSelect"
            />

            <BasicTab list={DASHBOARD_GOAL_TABS} />
          </div>
        </div>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={6}>
            <GoalList />
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <GoalChart />
          </Grid>
        </Grid>
      </Fragment>
    </div>
  );
};

export default DailyGoal;

import React from 'react';
import { Skeleton } from '@material-ui/lab';
const AnnouncementsSkeleton = () => {
    const rows=2;
    return (
    
        <div style={{width:'100%',backgroundColor:'white',padding:'12px',display:'flex',flexDirection:'column', justifyContent:'space-between',gap:'20px'}}>
            {Array.from({ length: rows }, (_, index) => (
        <div
          key={index}
          style={{ width: '100%', display: 'flex', gap: '20px' }}
        >
          <Skeleton variant="rect" width={'150px'} height={'85px'} />
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: '8px'
            }}
          >
            <Skeleton variant="rect" width={'60%'} height={'18px'} />
            <Skeleton variant="rect" width={'75%'} height={'18px'} />
            <Skeleton variant="rect" width={'30%'} height={'18px'} />
          </div>
        </div>
      ))}
        </div>
    
    );
};

export default AnnouncementsSkeleton;

import {  styled } from "@material-ui/core";
import React, { useState } from "react";
import Title from "../common/Title";
import {
  ACTIVITY_TYPE_APPOINTMENT,
  ACTIVITY_TYPE_CALL, ACTIVITY_TYPE_DEAL, ACTIVITY_TYPE_EMAIL, ACTIVITY_TYPE_TASK,
  ACTIVITY_TYPE_TEXT,
  DASHBOARD_ACTIVITY_LIST,
  FILTER_TYPE_TEAM,
  FILTER_TYPE_USER,
  TIME_FRAME,
  TIME_FRAME_VALUE
} from "../../../constants/CoreConstants";
import { useDispatch, useSelector } from "react-redux";
import { selectInsuranceDashboardState } from "../../../reducers/Insurance-dashboard/insuranceDashboardSelector";
import Utils from "../../../helpers/Utils";
import { fetchActivityStart } from "../../../reducers/Insurance-dashboard/insuranceDashboardSlice";
import UserChip from "../common/UserChip";
import moment from "moment";
import Call from "./Call";
import Email from "./Email";
import Deal from "./Deal";
import Appointment from "./Appointment";
import Task from "./Task";
import ActivitySkeleton from "../skeleton/ActivitySkeleton";
import BasicSelect from "../../common/BasicSelect";
import Text from "./Text";

const ActivityWrapper = styled("div")(({ theme }) => ({
  padding: 24,
  backgroundColor: "#ffffff",
  border: "1px solid #E9EAEC",
  borderRadius: 12,
  marginBottom:24,

  "& .header": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 24,

    "& .actions": {
      display: "flex",
      alignItems: "center",
      gap: 8,

      "& .userWrap": {
        display: "flex",
        alignItems: "center",
        gap: 2,
        cursor: "pointer",
      },
    },
  },


  "& .content": {
    "& .filter": {
      display: "flex",
      gap: "24px",
      marginBottom: 24,
    },

    "& .filterByActivity": {
      width:'50%'
    },
    "& .filterByDate": {
      flex: 5,
    },
    "& .datePickerWrapper": {
      width: '50%',
      border: '1px solid #ced4da',
      borderRadius: '6px',
      height: '38px',
    },
    "& .iconButton": {
      width:'100%',
      height:'38px',
      padding:'3px 8px',
      position:'relative',
      color:'#36454F',
      "&:focus": {
        backgroundColor: "transparent"
    }, 
    "&:hover": {
        backgroundColor: "transparent"
    }, 
    },
    "& .dateLabel": {
      position: 'absolute',
      top: '10px',
      left: '40px',
      fontSize: '15px',
      fontFamily: 'Poppins',
    },
    "& .calendarIcon": {
      position: 'absolute',
      top: '5px',
      left: '8px',
    },
    "& .arrowIcon": {
      position: 'absolute',
      top: '6px',
      right: '0px',
    },
  },
}));

export const EachActivityWrapper = styled("div")(({ theme, bgColor = "#ECF4FF" }) => ({
  width: "100%",
  minHeight: 148,

  padding: 8,
  backgroundColor: bgColor,
  borderRadius: 12,

  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: 24,

  "& .GoalText": {
    padding: 18,

    "& .subTitle": {
      marginBottom: 4,
    },
  },

  "& .goalIcon": {
    height: 48,
    width: 48,
    backgroundColor: "white",

    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    border: "1px solid #DEE0E3",
    borderRadius: 12,
  },
}));

const Activity = () => {
  const dispatch = useDispatch();
  const { loadingActivityData, selectedActivity, activityFilterType, activityDateFilterLabel, activityStartDate, activityEndDate, activity } = useSelector(selectInsuranceDashboardState);
  const [dateLabel,setDateLabel] = useState(TIME_FRAME_VALUE.TODAY);


  const renderActivity = () => {
    switch (selectedActivity) {
      case ACTIVITY_TYPE_CALL:
        return <Call activity={activity} />;
      case ACTIVITY_TYPE_EMAIL:
        return <Email activity={activity} />;
      case ACTIVITY_TYPE_DEAL:
        return <Deal activity={activity} />;
      case ACTIVITY_TYPE_APPOINTMENT:
        return <Appointment activity={activity} />;
      case ACTIVITY_TYPE_TASK:
        return <Task activity={activity} />;
      case ACTIVITY_TYPE_TEXT:
        return <Text activity={activity} />;
      default:
        return <Call activity={activity} />;
    }
  };

  const handleDate = (e) => {
    let startDate,endDate;
    const value = e.target.value;
    setDateLabel(value);
    if (value === TIME_FRAME_VALUE.TODAY) {
      startDate = Utils.startDateConvert(moment());
      endDate = Utils.endDateConvert(moment());
    } else if (value === TIME_FRAME_VALUE.YESTERDAY) {
      startDate =  Utils.startDateConvert(moment().subtract(1, "days"));
      endDate = Utils.endDateConvert(moment().subtract(1, "days"));
    }
    else if (value === TIME_FRAME_VALUE.LAST_7_DAYS) {
      startDate = Utils.startDateConvert(moment().subtract(6, "days"));
      endDate = Utils.endDateConvert(moment());
    }
    else if (value === TIME_FRAME_VALUE.LAST_30_DAYS) {
      startDate = Utils.startDateConvert(moment().subtract(29, "days"));
      endDate = Utils.endDateConvert(moment());
    }
    else if (value === TIME_FRAME_VALUE.THIS_MONTH) {
      startDate = Utils.startDateConvert(moment().startOf("month"));
      endDate = Utils.endDateConvert(moment().endOf("month"));
    }
    else if (value === TIME_FRAME_VALUE.LAST_MONTH) {
      startDate = Utils.startDateConvert(moment().subtract(1, "month").startOf("month"));
      endDate = Utils.endDateConvert(moment().subtract(1, "month").endOf("month"));
    }
    else if (value === TIME_FRAME_VALUE.ALL) {
      startDate = Utils.startDateConvert( moment('09/01/2020'));
      endDate = Utils.endDateConvert(moment());
    } else {
      startDate = Utils.startDateConvert(moment());
      endDate = Utils.endDateConvert(moment());
    }

    dispatch(fetchActivityStart({
      activityStartDate: startDate,
      activityEndDate: endDate,
      activityFilterType: activityFilterType,
      selectedActivity: selectedActivity,
      activityDateFilterLabel: value
    }));

  };

  return (
    <ActivityWrapper>
      <div className="header">
        <Title text="Activity" />

        <div className="actions">
          <UserChip filterUser={activityFilterType} onClickAction={() =>
            dispatch(fetchActivityStart({
              activityStartDate: activityStartDate,
              activityEndDate: activityEndDate,
              activityFilterType: activityFilterType === FILTER_TYPE_TEAM ? FILTER_TYPE_USER : FILTER_TYPE_TEAM,
              selectedActivity: selectedActivity,
              activityDateFilterLabel: activityDateFilterLabel
            })
            )} />
        </div>
      </div>

      <div className="content">
        <div className="filter">
          <BasicSelect
            name="pipeline"
            options={DASHBOARD_ACTIVITY_LIST}
            value={selectedActivity}
            onChange={(e) => {
              dispatch(fetchActivityStart({
                activityStartDate: activityStartDate,
                activityEndDate: activityEndDate,
                activityFilterType: activityFilterType,
                selectedActivity: e.target.value,
                activityDateFilterLabel: activityDateFilterLabel
              }));
            }}
            mapping={{
              label: "label",
              value: "value",
            }}
            className={"filterByActivity"}
            fullWidth
          />
        
             <BasicSelect
            name="pipeline"
            options={TIME_FRAME}
            onChange={handleDate}
            value={dateLabel}
            mapping={{
              label: "label",
              value: "value",
            }}
            className={"filterByActivity date"}
            fullWidth
            defaultText="Select a date"
            maxHeight="295px"
          />
            
        </div>

        {loadingActivityData ? <ActivitySkeleton /> : renderActivity()}

      </div>
    </ActivityWrapper>
  );
};

export default Activity;

import { styled } from "@material-ui/core";
import React, { Fragment } from "react";
import Title from "../../common/Title";
import BasicTab from "../../common/BasicTab";
import SubTitle from "../../common/SubTitle";
import { PercentArrowDownIcon } from "../../common/Icons";
import { DASHBOARD_GOAL_TABS } from "../../../../constants/CoreConstants";

const MonthlyGoalWrapper = styled("div")(({ theme }) => ({
  padding: 24,
  backgroundColor: "#ffffff",
  border: "1px solid #E9EAEC",
  borderRadius: 12,

  "& .header": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 28,
    pointerEvents: 'none',

    "& .dateLabel": {
      margin: 0,
    },
    "& .dateSmall": {
      fontSize: "14px",
      display: "block",
    },
  },

  "& .contentWrapper": {
    display: "flex",
    flexDirection: "column",
    gap: 50,

    "& .trending": {
      padding: 16,
      borderRadius: 12,
      border: "1px solid #E9EAEC",

      display: "flex",
      justifyContent: "space-between",
      boxShadow: "0px 1px 2px 0px #14151A0D",

      "& .GoalText": {
        padding: "8px",

        "& .title": {
          marginBottom: 4,
        },
      },

      "& .trendingTitle": {
        color: "#7E869A",
        fontWeight: 500,
      },
    },

    "& .lastMonth": {
      padding: "8px 16px",
      borderRadius: 12,
      backgroundColor: "#F7F7F8",
      borderTop: "2px solid #3e50fe",

      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      gap: 24,
      flexWrap: "wrap",

      "& .percentAchieved": {
        backgroundColor: "#fff",
        borderRadius: 12,
        padding: 18,

        display: "flex",
        flexDirection: "column",

        "& .titleWrapper": {
          display: "flex",
          alignItems: "center",
          gap: 8,
        },

        "& .subTitle": {
          color: "#7E869A",
          fontSize: 14,
        },
      },
      "& .lastAchieved": {
        display: "flex",
        flexDirection: "column",
        gap: 12,
        marginRight: 24,
      },
    },
    "& .currentGoal": {
      padding: "8px 16px",
      borderRadius: 12,
      backgroundColor: "#F7F7F8",
      borderTop: "2px solid #ff0055",

      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      gap: 24,
      flexWrap: "wrap",

      "& .percentAchieved": {
        backgroundColor: "#fff",
        borderRadius: 12,
        padding: 18,

        display: "flex",
        flexDirection: "column",

        "& .titleWrapper": {
          display: "flex",
          alignItems: "center",
          gap: 8,
        },

        "& .subTitle": {
          color: "#7E869A",
          fontSize: 14,
        },
      },
      "& .lastAchieved": {
        display: "flex",
        flexDirection: "column",
        gap: 12,
        marginLeft: 16,
      },
    },
  },
}));

const MonthlyGoal = () => {


  return (
    <MonthlyGoalWrapper className="monthlyGoal">
      <Fragment>
        <div className="header">
          <Title text={"This Quarter Stats"} />
          <BasicTab
            list={DASHBOARD_GOAL_TABS}
          />
        </div>

        <div className="contentWrapper">
          <div className="trending">
            <div className="GoalText">
              <Title className="title" text={"$63643"} />
            </div>
            <div>
              <SubTitle text={"TRENDING"} className="trendingTitle" />
            </div>
          </div>

          <div className="lastMonth">
            <div className="percentAchieved">
              <div className="titleWrapper">
                <Title text={"100%"} />
                <PercentArrowDownIcon
                  color="#33f28b"
                  style={{ transform: "rotate(180deg)" }}
                />
              </div>

              <SubTitle text={`VS LAST`} className="subTitle" />
            </div>
            <div className="lastAchieved">
              <Title text={1000} />
              <SubTitle text={`LAST`} className="subTitle" />
            </div>
          </div>

          {/* <div className="currentGoal">
            <div className="lastAchieved">
              <Title text={"$23564"} />
              <SubTitle text={"CURRENTLY AT"} className="subTitle" />
            </div>
            <div className="percentAchieved">
              <div className="titleWrapper">
                <Title text={"3634"} />
                <PercentArrowDownIcon
                  color="#33f28b"
                  style={{ transform: "rotate(180deg)" }}
                />
              </div>

              <SubTitle text={"OF GOAL"} className="subTitle" />
            </div>
          </div> */}
        </div>
      </Fragment>
    </MonthlyGoalWrapper>
  );
};

export default MonthlyGoal;

import React from "react";
import {TeamIcon, UserIcon} from "./Icons";
import SubTitle from "./SubTitle";
import {FILTER_TYPE_TEAM} from "../../../constants/CoreConstants";

const UserChip = ({ filterUser, onClickAction }) => {
    return (
        <div className="userWrap" onClick={onClickAction}>
            {filterUser === FILTER_TYPE_TEAM ? <TeamIcon/> : <UserIcon/>}
            <SubTitle text={filterUser === FILTER_TYPE_TEAM ? "Team" : "Me"}/>
        </div>
    );
};

export default UserChip;
import {
  Box,
  Button,
  Dialog,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import Utils from "../../../helpers/Utils";
import { ANNOUNCEMENT_DATA } from "../../../constants/CoreConstants";

//  export default styles; // Export the styles object
const useStyles = makeStyles({
  headerContainer: {
    width:'100%',
    display:'flex', 
    justifyContent:"space-between", 
    alignItems:'center',
    padding:'16px',
    borderBottom: "1px solid #CED4DB"
  },
  headerTitleImgContainer: {
    width:'100%',
    display: "flex", 
    alignItems: "center", 
    gap:'12px'
  },
  titleHeader: {
    fontSize: "18px",
    color:'var(--dark_blue)',
    width:'100%'
  },

  CloseButton: {
    fontWeight: 600,
    background: "#346fef",
    padding: "10px 46px",
    color: "white",
    "&:focus,&:hover": {
      background: "#346fef",
    },
  },
  cardWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: '16px',
  },
  imageWrapper: {
    maxWidth: "100%",
    display: "flex",
    justifyContent: "center"
  },
  image: {
    height: "auto",
    width: "100%",
    maxWidth: "500px",
    borderRadius: 10,
    objectFit: "cover"
  },
  videoPlayer: {
    height: "auto",
    width: "100%",
    maxWidth: "600px",
  },
  textWrapper: {
    width: "100%",
    marginTop: "20px",
    color:'var(--dark_blue)',
    fontWeight: 400,
    padding:'0px 40px',
  },
  title: {
    fontSize: 24,
    fontWeight: 600,
    lineHeight: "32px",
    color: "#14151A",
    margin: 0,
    paddingBottom: "20px",
  },
  announceDate: {
    fontSize: "15px",
    fontWeight: 500,
    color:'var(--dark_blue)',
    width:'110px',
  },
  scrollable: {
    width: "100%",
    height: '65vh',
    overflowY: "auto",
    paddingRight: "6px",

    "&::-webkit-scrollbar": {
      width: "6px",
      background: "#FFFFFF",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#627692",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#fff",
      borderRadius: "10px",
    },
  }
});

const renderMediaContent = (item, classes) => {
  switch (item.fileType) {
    case ANNOUNCEMENT_DATA.FILE_TYPE_IMAGE:
      return item.imageUrl ? (
        <div className={classes.imageWrapper}>
          <img src={item.imageUrl} alt="announcement" className={classes.image} />
        </div>
      ) : null;

    case ANNOUNCEMENT_DATA.FILE_TYPE_VIDEO:
      return item.imageUrl ? (
        <div className={classes.imageWrapper}>
          <video className={classes.videoPlayer} src={item.imageUrl} controls></video>
        </div>
      ) : null;

    case ANNOUNCEMENT_DATA.FILE_TYPE_EMBED_CODE:
      return item.embedCode ? (
        <div className={classes.imageWrapper}>
          <div dangerouslySetInnerHTML={{ __html: item.embedCode }} />
        </div>
      ) : null;

    default:
      return null;
  }
};

const AnnouncementDetailsModal = ({ open, onClose, item }) => {
  const classes = useStyles();

  return (
    <Dialog
      PaperProps={{
        style: {
          width: "100%",
          maxWidth: "880px",
        },
      }}
      open={open}
      onClose={onClose}
    >
      <Box
        style={{ width: "100%", maxWidth: "880px" }}
        minHeight={300}
        sx={{ "& .MuiTypography-root": { height: "unset !important" } }}
      >
        <div className={classes.headerContainer}>
          <div className={classes.headerTitleImgContainer}>
            <img src={ANNOUNCEMENT_DATA.MODAL_IMAGE} alt="" style={{ height: "50px" }} />
            <div style={{width:'100%'}}>
              <Typography
                variant="body1"
                className={classes.titleHeader}
              >
                {item.title}
              </Typography>
            </div>
          </div>

          <div className={classes.announceDate}>{window.globalTimezoneConversionToDifferentTimezone(item.createdAt, Utils.getAccountData("userTimezoneRaw"), 'UTC', 'MMM D, YYYY')}</div>
        </div>

        <div className={classes.cardWrapper} >
          <div className={classes.scrollable}>

            {renderMediaContent(item, classes)}
            <div className={classes.textWrapper} dangerouslySetInnerHTML={{ __html: item.description }} />

          </div>
        </div>

        <Box padding={2} display={"flex"} justifyContent={"center"}>
          <Box display={"flex"} gridGap={8} sx={{ fontWeight: 600 }}>
            <Button
              onClick={onClose}
              className={classes.CloseButton}
              variant="contained"
            >
              Close
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default AnnouncementDetailsModal;

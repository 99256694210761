import React from "react";
import {checkCookie} from "../../helpers/Cookie";

const withAuthCheck = (WrappedComponent) => {
    return (props) => {
        const auth = checkCookie(process.env.REACT_APP_ACCESS_TOKEN);

        if (auth) {
            return <WrappedComponent {...props} />;
        } else {
            window.open("/user/login", "_self");
            return null;
        }
    };
};

export default withAuthCheck;
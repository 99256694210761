import Utils from '../../../helpers/Utils';
import MessageBox from '../components/message-box/MessageBox';
import Styles from '../styles.module.css';

const EinConfirmation = (props) => {

    const getReasonMessage = (text) => {
        if(text !== undefined && text !== null && text !== ""){
            try{
                let str = text.split("Reason")
                str = str[1];
                str = str.replace(":", "")
                str = str.trim()
                return (
                    <MessageBox 
                        message="Thank you for submitting your information. Unfortunately, we were unable to process your registration successfully due to the reason stated below. If you have further questions please reach out to our team."
                        reason={str}
                    />
                )
            }
            catch(error){
                return (
                    <MessageBox 
                        message={text}
                    />
                )
            }
        }
        return (
            <MessageBox 
                message={text}
            />
        )
    }

    if(props.warningScreen){
        return (
            <div className={`${Styles.tdp_ein_confirmation_wrp_alt} `}>
                <div className={`${Styles.tdp_aw_des_wrp} tdp_scroll_bar`}>
                    <span><b>Notice</b> - Please Read: Earlier this year some changes were made in regards to how businesses send text messages in the United States. These changes were rolled out by all the major carrier networks like T-Mobile, Sprint, Verizon, and others.</span>
                    <span>The changes were designed to increase consumer trust, reduce unwanted spam, and give businesses better deliverability across the network. This industry-wide change is referred to as A2P 10DLC. </span>
                    <span className={Styles.tdp_aw_dw_title}>Why I am seeing it ?</span>
                    <span>
                        The phone companies rolled out the first version of 10DLC back in March and they are rolling out some revisions this month, November 2022. 

                        We will provide updates like this as we get more info. If you’ve already registered for 10DLC these changes will have little to no effect on your business. If you haven’t registered yet you may see a decrease in your text message delivery rate until you register. 
                    </span>
                    <span>
                        <b>FYI:</b> The biggest change that is coming is phone companies will begin requiring an EIN# (tax id) to register your business to send text messages. They have plans to roll out an option for users without a EIN#, but they will restrict the number of messages you can send. 
                        If your business doesn’t currently have an EIN#, I would strongly suggest getting one. This is the direction this new regulation is heading.
                    </span>
                    <div>
                        <span>To learn more 10DLC FAQ’s, please visit- </span>
                        <a className={Styles.tdp_aw_dw_link} href="//support.twilio.com/hc/en-us/articles/1260800720410" target={'_blank'} rel="noreferrer">What-is-A2P-10DLC ?</a>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className={Styles.tdp_ein_confirmation_wrp}>
            {( props.information !== undefined && props.information != null && props.information.standard_reg_failed_reason !== "") &&
                getReasonMessage(props.information.standard_reg_failed_reason)
            }
            <div className={Styles.alertBannerWrapper}>
                <div className={Styles.alertBanner}>
                    <p>Mandatory Registration</p>
                </div>
            </div>
            <div className={Styles.tdp_ecw_header_wrp}>
                <span className={Styles.tdp_ecwhw_title}>Welcome to </span>
                <span className={Styles.tdp_ecwhw_agency}>{Utils.getAccountData('AuthUserAgencyName')}</span>
            </div>
            <div className={Styles.tdp_ecw_message_wrp}>
                <span className={Styles.tdp_ecwmw_text}>
                    Starting from March 2022, the United States implemented new regulations known as A2P/10DLC, which mandate that businesses must register with phone companies in order to send text messages. This registration is compulsory, and failure to comply will result in users being unable to use telephony services after July 5th, 2023. Please select an option below to register your business.
                </span>
            </div>
            <div className={Styles.tdp_ecw_action_wrp}>
                <span className={Styles.tdp_ecwaw_label}>Does your business have an EIN# ?(Tax ID)</span>
                <div className={Styles.tdp_ecwaw_button_wrp}>
                    <span className={Styles.tdp_ecwawbw_button} onClick={() => props.handleShowVolumnScreen()}>Yes</span>
                    <span className={Styles.tdp_ecwawbw_button} onClick={() => props.handleClick()}>No</span>
                </div>
            </div>
        </div>
    );
}
export default EinConfirmation;
import React from "react";
import {
    Container,
    styled
} from "@material-ui/core";
import BuildingBasicTabs from "./BuildingBasicTabs";
import BuildingBasicTabContent from "./BuildingBasicTabContent";



const BuildingBasicWrapper = styled(Container)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
    gap: 24,
  },
}));
const BuildingBasic = () => {
  return (
    <BuildingBasicWrapper maxWidth="lg">
      <BuildingBasicTabs />
      <BuildingBasicTabContent />
    </BuildingBasicWrapper>
  );
};

export default BuildingBasic;

import { IconButton } from "@material-ui/core";
import moment from "moment";
import React from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { useDispatch, useSelector } from "react-redux";
import { DATEPICKER_INITIAL_SETTING, FILTER_TYPE_TEAM, FILTER_TYPE_USER } from "../../../constants/CoreConstants";
import Utils from "../../../helpers/Utils";
import { selectInsuranceDashboardState } from "../../../reducers/Insurance-dashboard/insuranceDashboardSelector";
import { fetchLeadCountStart } from "../../../reducers/Insurance-dashboard/insuranceDashboardSlice";
import { ArrowRightIcon, CalenderIcon } from "../common/Icons";
import SubTitle from "../common/SubTitle";
import Title from "../common/Title";
import UserChip from "../common/UserChip";
import { CardWrapper, GoButton } from "./index";

const NewLeadsCard = ({ goToLink }) => {
  const dispatch = useDispatch();
  const { totalLeadCountFilterType, totalLeadCount, totalLeadCountStartDate, totalLeadCountEndDate } = useSelector(selectInsuranceDashboardState);

  const handleDateCallback = (start, end) => {

    let utcStartDate = start.format('YYYY-MM-DD');
    let utcEndDate = end.format('YYYY-MM-DD');

    let startDate = window.globalTimezoneConversionToDifferentTimezone(utcStartDate + ' 00:00:00', Utils.getAccountData("userTimezoneRaw"), 'UTC', 'YYYY-MM-DD HH:mm:ss')
    let endDate = window.globalTimezoneConversionToDifferentTimezone(utcEndDate + ' 23:59:59', Utils.getAccountData("userTimezoneRaw"), 'UTC', 'YYYY-MM-DD HH:mm:ss')
    dispatch(fetchLeadCountStart({ totalLeadCountStartDate: startDate, totalLeadCountEndDate: endDate, totalLeadCountFilterType: totalLeadCountFilterType }))
  }

  return (
    <CardWrapper className="newLeadsCard">
      <div>
        <div className="header">
          <Title text={"New Leads"} className="title" />

          <div className="actions">
            <UserChip filterUser={totalLeadCountFilterType} onClickAction={() => dispatch(fetchLeadCountStart({ totalLeadCountStartDate: totalLeadCountStartDate, totalLeadCountEndDate: totalLeadCountEndDate, totalLeadCountFilterType: totalLeadCountFilterType === FILTER_TYPE_TEAM ? FILTER_TYPE_USER : FILTER_TYPE_TEAM }))} />

            <div>
              <DateRangePicker
                initialSettings={{
                  ...DATEPICKER_INITIAL_SETTING,
                  startDate: moment(),
                  endDate: moment(),
                }}
                onCallback={handleDateCallback}
              >
                <IconButton size="small">
                  <CalenderIcon />
                </IconButton>
              </DateRangePicker>
            </div>
          </div>
        </div>
      </div>
      <div className="body">
        <div>
          {/* {
            loadingLeadCount ? */}
          {/* <Skeleton variant="text" width={100} height={32} /> : */}
          <Title text={totalLeadCount} className="value" />
          {/* } */}
          <SubTitle text={"Total Leads"} />
        </div>
        <GoButton
          variant="outlined"
          color="primary"
          endIcon={<ArrowRightIcon className="goIcon" />}
          fullWidth
          onClick={() => goToLink('/contacts')}
        >
          Go
        </GoButton>
      </div>
    </CardWrapper>
  );
};

export default NewLeadsCard;

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import './index.css';
import App from './App';
import store from "./store/Store"
import * as serviceWorker from './serviceWorker';

window.renderDashboardModule = (containerId, history) => {
    ReactDOM.render(
        <React.StrictMode>
            <Provider store={store}>
                <App history={history} />
            </Provider>
        </React.StrictMode>,
        document.getElementById(containerId)
    );
    serviceWorker.unregister();
};

window.unmountDashboardModule = containerId => {
    try {
        ReactDOM.unmountComponentAtNode(document.getElementById(containerId));
    } catch (e) {
        console.log(e);
    }
};

if (process.env.REACT_APP_PRODUCTION_MODE !== true && document.getElementById('DashboardModule-root')) {
    ReactDOM.render(
        <React.StrictMode>
            <Provider store={store}>
                <App />
            </Provider>
        </React.StrictMode>,
        document.getElementById('DashboardModule-root'));
    serviceWorker.unregister();
}
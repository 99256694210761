export const ONBOARD_STATUS = {
    COMPLETED: "COMPLETED",
    NOT_COMPLETE: "NOT_COMPLETE"
};

export const JOIN_FACEBOOK_GROUP = "JOIN_FACEBOOK_GROUP";
export const SELECT_COMPANY = "SELECT_COMPANY";
export const SELECT_PRODUCT = "SELECT_PRODUCT";
export const SET_SALES_GOAL = "SET_SALES_GOAL";
export const CREATE_CONTACT = "CREATE_CONTACT";
export const IMPORT_CONTACT = "IMPORT_CONTACT";
export const CONNECT_EMAIL = "CONNECT_EMAIL";
export const GET_VIRTUAL_NUMBER = "GET_VIRTUAL_NUMBER";
export const ADD_TEAM_MEMBER = "ADD_TEAM_MEMBER";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import React, { useState } from 'react';
import BootstrapTooltip from "../../globals/BootstrapTooltip";
import HelpIcon from "@material-ui/icons/Help";
let registerStadtardPdf = `${process.env.REACT_APP_CDN_LINK}asset/images/releases/1.0.0/standard-lowvolume.pdf`;

const TermPolicy = ({ handleBack, handleSubmit, submitting}) => {

    const [accept, setAccept] = useState(false)

    const handleSubmitButton = () => {
        if(!accept || submitting){
            return
        }
        handleSubmit()
    }

    return (
        <div className="twilio___migration__policy_step twili__migration___container">
            <div className="row" style={{ position: "relative" }}>
                <div className="col s12">
                    <div style={{ position: "absolute", right: "12px", border: "1px solid #dd1616", borderRadius: "4px", padding: "2px"}}>
                        <BootstrapTooltip arrow title={"Standard A2P Registration"}>
                            <a href={registerStadtardPdf} target="_blank"  rel="noreferrer" className="ml-2" style={{ display: "flex", alignItems: "center", fontSize: "14px", color: "#dd1616", gap: "2px" }}>
                                Get Help
                                <HelpIcon style={{ color: "#dd1616" }}/>
                            </a>
                        </BootstrapTooltip>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col s12">
                    <h6 className="twilio__label__v2 text-dark-blue m-0 text-left mb-2">Terms Of Service and Privacy Policy</h6>
                    
                    <p>We will process personal data according to the <a href="https://www.twilio.com/legal/privacy" target="_blank" rel="noreferrer"><b>Twilio Privacy Statement</b></a>.</p>

                    <div className='policy__declaration'>
                    {accept ?
                        <div onClick={() => setAccept(false)}>
                            <CheckBoxIcon />
                        </div>
                    :
                        <div onClick={() => setAccept(true)}>
                            <CheckBoxOutlineBlankIcon />
                        </div>
                    } 
                        <span>
                        I declare that the information provided is accurate.
                        I acknowledge that Twilio will
                        be processing the information provided for the purpose of identity verification,
                        and that Twilio reserves the right to retain the Business Profile information after
                        account closure in the case of a traceback from a authority or equivalent.
                        </span>
                    </div>
                </div>
            </div>

            <div className="row">
                <div 
                    className="col s12 twilio__migration__submit__btn" 
                    style={(!accept) ? {'background': '#9e9e9e', 'cursor': 'not-allowed'} : {}} 
                    onClick={handleSubmitButton}
                >
                    {submitting ? 'Submitting ' : 'Submit '} Information
                </div>

                <div 
                    className="col s12 twilio__migration__submit__btn"  
                    onClick={handleBack}
                >
                    Back
                </div>
            </div>
        </div>
    )
}
export default TermPolicy;
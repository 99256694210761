import { useEffect, useState } from "react";
import socketIOClient from "socket.io-client";


const TestSocket = () => {

    const [socket, setSocket] = useState(null);
    const [isConnected, setIsConnected] = useState(false)
    useEffect(() => {
        // connect to the socket server
        const socketIo = socketIOClient(process.env.REACT_APP_NODE_SERVER);

        // set up heartbeat checks
        let heartbeatInterval;
        let missedHeartbeats = 0;
        const MAX_MISSED_HEARTBEATS = 3;

        const sendHeartbeat = () => {
            if (missedHeartbeats < MAX_MISSED_HEARTBEATS) {
                socketIo.emit('join-test-socket-room');
                missedHeartbeats++;
            } else {
                console.log('Missed too many heartbeats, disconnecting');
                socketIo.disconnect();
            }
        };

        const startHeartbeat = () => {
            heartbeatInterval = setInterval(() => {
                sendHeartbeat();
            }, 1000);
        };

        const resetHeartbeat = () => {
            missedHeartbeats = 0;
        };

        socketIo.on('connect', () => {
            console.log('Connected to socket server');
            setIsConnected(true);
            startHeartbeat();
        });

        socketIo.on('disconnect', () => {
            console.log('Disconnected from socket server');
            setIsConnected(false);
            clearInterval(heartbeatInterval);
        });

        socketIo.on('pong', () => {
            console.log('Received pong');
            resetHeartbeat();
        });

        setSocket(socketIo);

        // clean up on unmount
        return () => {
            if (socketIo) {
                socketIo.disconnect();
            }
        };
    }, [])

    useEffect(() => {

        if(socket && isConnected){
            socket.on("test-socket", testSocketControl);
        }

    }, [socket]); // eslint-disable-line react-hooks/exhaustive-deps


    const testSocketControl = (data) => {

        console.log(data);
    };


    return (
        <>
            <div>
                <p>hello</p>
            </div>
        </>
    )

}

export default TestSocket;
import { takeLatest, call, put } from 'redux-saga/effects';
import {
    fetchActiveDealCountFail,
    fetchActiveDealCountStart,
    fetchActiveDealCountSuccess,
    fetchActivityFail,
    fetchActivityStart,
    fetchActivitySuccess,
    fetchAppointmentCountFail,
    fetchAppointmentCountStart,
    fetchAppointmentCountSuccess,
    fetchDashboardInitialDataFail,
    fetchDashboardInitialDataStart,
    fetchDashboardInitialDataSuccess,
    fetchLeadCountFail,
    fetchLeadCountStart,
    fetchLeadCountSuccess, fetchUserSalesGoalFail, fetchUserSalesGoalStart,
    fetchUserSalesGoalSuccess,
    fetchUserStageInfoFail,
    fetchUserStageInfoStart,
    fetchUserStageInfoSuccess,
    fetchAnnouncementStart,
    fetchAnnouncementSuccess,
    fetchAnnouncementFail,
    fetchUserActivity,
    fetchUserActivitySuccess,
    fetchUserActivityFail,
    fetchUserInbox,
    fetchUserInboxSuccess,
    fetchUserInboxFail,
    fetchSidebarNotification,
    fetchSidebarNotificationSuccess,
    fetchSidebarNotificationFail
} from './insuranceDashboardSlice';
import insuranceDashboardService from './insuranceDashboardService';
import { FILTER_TYPE_TEAM } from '../../constants/CoreConstants';

// Fetch Insurance Dashboard Initial Data
function* fetchInsuranceDashboardInitialData() {

    try {

        const response = yield call(insuranceDashboardService.getDashboardInitialData);

        if (response.success) {
            yield put(fetchDashboardInitialDataSuccess(response.data));

        } else {
            yield put(fetchDashboardInitialDataFail(response.message ?? 'Something went wrong'));
        }
    } catch (error) {
        yield put(fetchDashboardInitialDataFail(error.message));
    }
}

// Fetch Insurance Dashboard Lead Count Data
function* fetchLeadCount(action) {
    try {
        // Decide the API call based on the filter type
        const apiCall = action.payload.totalLeadCountFilterType === FILTER_TYPE_TEAM ?
            insuranceDashboardService.getTeamLeadCount : insuranceDashboardService.getUserLeadCount;
        // Execute the API call
        const response = action.payload.totalLeadCountStartDate ? yield call(apiCall, { startDate: action.payload.totalLeadCountStartDate, endDate: action.payload.totalLeadCountEndDate }) : yield call(apiCall);
        // Check for successful response
        if (response?.success) {
            yield put(fetchLeadCountSuccess(response.data));
        } else {
            yield put(fetchLeadCountFail(response?.message || 'Something went wrong'));
        }
    } catch (error) {
        // Handle API or execution errors
        yield put(fetchLeadCountFail(error.message || 'An unexpected error occurred'));
    }
}

// Fetch Insurance Dashboard Active Deal count Data
function* fetchActiveDealCounts(action) {
    try {
        // Decide the API call based on the filter type
        const apiCall = action.payload.totalActiveDealCountFilterType === FILTER_TYPE_TEAM ?
            insuranceDashboardService.getTeamActiveDealCount : insuranceDashboardService.getUserActiveDealCount;
        // Execute the API call
        const response = action.payload.totalActiveDealStartDate ? yield call(apiCall, { startDate: action.payload.totalActiveDealStartDate, endDate: action.payload.totalActiveDealEndDate }) : yield call(apiCall);

        if (response?.success) {
            yield put(fetchActiveDealCountSuccess(response.data));
        } else {
            yield put(fetchActiveDealCountFail(response?.message || 'Something went wrong'));
        }
    } catch (error) {
        // Handle API or execution errors
        yield put(fetchActiveDealCountFail(error.message || 'An unexpected error occurred'));
    }
}

// Fetch Insurance Dashboard Appointments count Initial Data
function* fetchAppointmentCounts(action) {
    try {
        // Decide the API call based on the filter type
        const apiCall = action.payload.userFilter === FILTER_TYPE_TEAM ?
            insuranceDashboardService.getTeamAppointmentCount : insuranceDashboardService.getUserAppointmentCount;

        // Execute the API call
        const response = yield call(apiCall, action.payload);


        // Check for successful response
        if (response?.success) {
            yield put(fetchAppointmentCountSuccess(response.data));
        } else {
            yield put(fetchAppointmentCountFail(response?.message || 'Something went wrong'));
        }
    } catch (error) {
        // Handle API or execution errors
        yield put(fetchAppointmentCountFail(error.message || 'An unexpected error occurred'));
    }
}

// Fetch Insurance Dashboard Activity
function* fetchActivity(action) {
    try {
        // Decide the API call based on the filter type
        const apiCall = action.payload.activityFilterType === FILTER_TYPE_TEAM ?
            insuranceDashboardService.getTeamActivity : insuranceDashboardService.getUserActivity;

        // Execute the API call
        const payload = {
            selectedActivity: action.payload.selectedActivity,
        };

        if (action.payload.activityStartDate) {
            payload.startDate = action.payload.activityStartDate;
            payload.endDate = action.payload.activityEndDate;
        }

        const response = yield call(apiCall, payload);

        // Check for successful response
        if (response?.success) {
            yield put(fetchActivitySuccess(response.data));
        } else {
            yield put(fetchActivityFail(response?.message || 'Something went wrong'));
        }
    } catch (error) {
        // Handle API or execution errors
        yield put(fetchActivityFail(error.message || 'An unexpected error occurred'));
    }
}

// Fetch Insurance Dashboard User Stage Info
function* fetchUserStageInfo(action) {
    try {
        // Decide the API call based on the filter type
        const apiCall = action.payload.userStageInfoFilterType === FILTER_TYPE_TEAM ?
            insuranceDashboardService.getTeamStageInfo : insuranceDashboardService.getUserStageInfo;

        const payload = {
            pipelineId: action.payload.selectedPipeline,
        };

        if (action.payload.userStageInfoStartDate) {
            payload.startDate = action.payload.userStageInfoStartDate;
            payload.endDate = action.payload.userStageInfoEndDate;
        }

        const response = yield call(apiCall, payload);

        // Check for successful response
        if (response?.success) {
            yield put(fetchUserStageInfoSuccess(response.data));
        } else {
            yield put(fetchUserStageInfoFail(response?.message || 'Something went wrong'));
        }
    } catch (error) {
        // Handle API or execution errors
        yield put(fetchUserStageInfoFail(error.message || 'An unexpected error occurred'));
    }
}

// Fetch Insurance Dashboard User Stage Info
function* fetchUserSalesGoal(action) {
    try {
        // Decide the API call based on the filter type
        const apiCall = insuranceDashboardService.getUserSaleGoalStats;

        const payload = {
            type: action.payload.goalStatFilter,
            user_sale_goal_id: action.payload.userSaleGoalId
        };

        const response = yield call(apiCall, payload);

        // Check for successful response
        if (response?.success) {
            yield put(fetchUserSalesGoalSuccess(response.data));
        } else {
            yield put(fetchUserSalesGoalFail(response?.message || 'Something went wrong'));
        }
    } catch (error) {
        // Handle API or execution errors
        yield put(fetchUserSalesGoalFail(error.message || 'An unexpected error occurred'));
    }
}

function* fetchAnnouncementList(action) {
    try {
        // Decide the API call based on the filter type
        const apiCall = insuranceDashboardService.getAnnouncementList;


        const response = yield call(apiCall);

        // Check for successful response
        if (response?.success) {
            yield put(fetchAnnouncementSuccess(response.data));
        } else {
            yield put(fetchAnnouncementFail(response?.message || 'Something went wrong'));
        }
    } catch (error) {
        // Handle API or execution errors
        yield put(fetchAnnouncementFail(error.message || 'An unexpected error occurred'));
    }
}

function* fetchSidebarActivity(action) {
    try {
        const apiCall = insuranceDashboardService.getUserActivities;
        const response = yield call(apiCall, action.payload);

        // Check for successful response
        if (response?.success) {
            yield put(fetchUserActivitySuccess(response.data));
        } else {
            yield put(fetchUserActivityFail(response?.message || 'Something went wrong'));
        }
    } catch (error) {
        // Handle API or execution errors
        yield put(fetchUserActivityFail(error.message || 'An unexpected error occurred'));
    }
}

function* fetchSidebarInbox(action) {
    try {
        const apiCall = insuranceDashboardService.getUserInboxData;
        const response = yield call(apiCall, action.payload);

        // Check for successful response
        if (response?.success) {
            yield put(fetchUserInboxSuccess(response.data));
        } else {
            yield put(fetchUserInboxFail(response?.message || 'Something went wrong'));
        }
    } catch (error) {
        // Handle API or execution errors
        yield put(fetchUserInboxFail(error.message || 'An unexpected error occurred'));
    }
}

function* fetchSidebarNotifications(action) {
    try {
        const apiCall = insuranceDashboardService.getUserNotifications;
        const response = yield call(apiCall, action.payload);

        // Check for successful response
        if (response?.success) {
            yield put(fetchSidebarNotificationSuccess(response.data));
        } else {
            yield put(fetchSidebarNotificationFail(response?.message || 'Something went wrong'));
        }
    } catch (error) {
        // Handle API or execution errors
        yield put(fetchSidebarNotificationFail(error.message || 'An unexpected error occurred'));
    }
}

export function* watchDashboardSaga() {
    yield takeLatest(fetchDashboardInitialDataStart.type, fetchInsuranceDashboardInitialData);
    yield takeLatest(fetchLeadCountStart.type, fetchLeadCount);
    yield takeLatest(fetchActiveDealCountStart.type, fetchActiveDealCounts);
    yield takeLatest(fetchAppointmentCountStart.type, fetchAppointmentCounts);
    yield takeLatest(fetchActivityStart.type, fetchActivity);
    yield takeLatest(fetchUserStageInfoStart.type, fetchUserStageInfo);
    yield takeLatest(fetchUserSalesGoalStart.type, fetchUserSalesGoal);
    yield takeLatest(fetchAnnouncementStart.type, fetchAnnouncementList);
    yield takeLatest(fetchUserActivity, fetchSidebarActivity);
    yield takeLatest(fetchUserInbox, fetchSidebarInbox);
    yield takeLatest(fetchSidebarNotification, fetchSidebarNotifications);
}

import moment from "moment";

const CoreConstants = {

}

export default CoreConstants;

export const DROPDOWN_BOTTOM_SETUP = {
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
    },
    transformOrigin: {
        vertical: "top",
        horizontal: "center",
    },
    getContentAnchorEl: null,
}

export const DATEPICKER_INITIAL_SETTING = {
    startDate: moment().startOf('month'),
    endDate: moment().endOf('month'),
    ranges: {
        'All': [
            moment('09/01/2020'), moment()
        ],
        'Last 30 Days': [
            moment().subtract(29, 'days'), moment()
        ],
        'Last 7 Days': [
            moment().subtract(6, 'days'), moment()
        ],
        'Last Month': [
            moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')
        ],
        'This Month': [
            moment().startOf('month'), moment().endOf('month')
        ],
        Today: [
            moment(), moment()
        ],
        Yesterday: [
            moment().subtract(1, 'days'), moment().subtract(1, 'days')
        ]
    }
}

export const FUNNEL_SETUP = {
    chart: {
        type: 'funnel'
    },
    title: {
        text: null
    },
    plotOptions: {
        series: {
            dataLabels: {
                enabled: true,
                format: '<b>{point.name}</b> ({point.y:,.0f})',
                softConnector: true
            },
            center: ['40%', '50%'],
            neckWidth: '30%',
            neckHeight: '25%',
            width: '80%'
        }
    },
    legend: {
        enabled: false
    },
    credits: {
        enabled: false
    },
    series: [{
        name: 'Total',
        data: [],
    }],

    responsive: {
        rules: [{
            condition: {
                maxWidth: 400
            },
            chartOptions: {
                plotOptions: {
                    series: {
                        center: ['50%', '50%'],
                        width: '100%'
                    }
                }
            }
        }]
    }
}

export const ACTIVITY_TYPE = {
    PAST: 1,
    PRESENT: 2,
    FUTURE: 3
}

export const TWILIO_SETUP = {
    policyTypeLowVolumn: 1,
    policyTypeHighVolumn: 2,
}

export const emailProvider = {
    DEFAULT: 0,
    NYLAS_V1: 1,
    NYLAS_V3: 2
}

export const connectedEmailStatus = {
    ACTIVE: "ACTIVE",
    INACTIVE: "INACTIVE",
    FAILED: "FAILED",
    TRASH: "TRASH"
};

export const NYLAS_V3_AGENCIES = [1, 125, 53, 82, 57, 106, 97, 16, 30, 62, 40, 87, 92, 103, 129, 38, 67, 85, 159, 193, 157, 45, 59, 190, 102, 145, 51, 201, 225, 73, 173, 42, 32, 150, 124, 44, 234, 226, 215, 214, 213, 46, 141, 218, 216, 219];

export const FREQUENCY = {
    WEEKLY: "WEEKLY",
    MONTHLY: "MONTHLY",
    QUARTERLY: "QUARTERLY",
    YEARLY: "YEARLY"
};
export const FILTER_TYPE_TEAM = 'TEAM';
export const FILTER_TYPE_USER = 'USER';

export const APPOINTMENT_SCHEDULE_PAST = 'PAST';
export const APPOINTMENT_SCHEDULE_TODAY = 'TODAY';
export const APPOINTMENT_SCHEDULE_UPCOMING = 'UPCOMING';

export const GOAL_TYPE_SALES = 'SALE';
export const GOAL_TYPE_POLICY = 'PRODUCT';

export const ACTIVITY_TYPE_CALL = "CALL";
export const ACTIVITY_TYPE_EMAIL = "EMAIL";
export const ACTIVITY_TYPE_TASK = "TASK";
export const ACTIVITY_TYPE_APPOINTMENT = "APPOINTMENT";
export const ACTIVITY_TYPE_DEAL = "DEAL";
export const ACTIVITY_TYPE_TEXT = "TEXT";

export const DASHBOARD_ACTIVITY_LIST = [
    { value: ACTIVITY_TYPE_CALL, label: "Calls" },
    { value: ACTIVITY_TYPE_TEXT, label: "Texts" },
    { value: ACTIVITY_TYPE_EMAIL, label: "Emails" },
    { value: ACTIVITY_TYPE_TASK, label: "Tasks Completed" },
    { value: ACTIVITY_TYPE_DEAL, label: "Deals Closed" },
];

export const DATE_FILTER_LABEL = {
  ALL: "All",
  TODAY: "Today",
  CUSTOM: "Custom"
};

export const DASHBOARD_GOAL_TYPES = {
    PREMIUM: GOAL_TYPE_SALES,
    POLICIES: GOAL_TYPE_POLICY
};

export const DASHBOARD_GOAL_TABS = [
    {
        id: DASHBOARD_GOAL_TYPES.PREMIUM,
        title: "Premium",
    },
    {
        id: DASHBOARD_GOAL_TYPES.POLICIES,
        title: "Policies",
    },
];

export const PIE_CHART_SLICE_SIZE = {
    SMALL_SLICE: 0.08
}

export const TIME_FRAME_VALUE = {
    ALL: "All",
    LAST_30_DAYS : "Last 30 Days",
    LAST_7_DAYS: "Last 7 Days",
    LAST_MONTH : "Last Month",
    THIS_MONTH : "This Month",
    TODAY: "Today",
    YESTERDAY: "Yesterday"
};

export const TIME_FRAME = [
    { value: TIME_FRAME_VALUE.TODAY, label: "Today" },
    { value: TIME_FRAME_VALUE.YESTERDAY, label: "Yesterday" },
    { value: TIME_FRAME_VALUE.LAST_7_DAYS, label: "Last 7 Days" },
    { value: TIME_FRAME_VALUE.THIS_MONTH, label: "This Month" },
    { value: TIME_FRAME_VALUE.LAST_30_DAYS, label: "Last 30 Days" },
    { value: TIME_FRAME_VALUE.LAST_MONTH, label: "Last Month" },
    { value: TIME_FRAME_VALUE.ALL, label: "All" }
];

export const ANNOUNCEMENT_DATA = {
    DEFAULT_IMAGE: `${process.env.REACT_APP_CDN_LINK}assets/images/releases/announcements/1.0.0/announcement_text.jpg`,
    DEFAULT_VIDEO_IMAGE: `${process.env.REACT_APP_CDN_LINK}assets/images/releases/announcements/1.0.0/announcement_play.jpg`,
    MODAL_IMAGE: `${process.env.REACT_APP_CDN_LINK}assets/images/releases/announcements/1.0.0/announcement_icon.png`,
    FILE_TYPE_IMAGE: 'IMAGE',
    FILE_TYPE_VIDEO: 'VIDEO',
    FILE_TYPE_EMBED_CODE: 'EMBED_CODE'
}
import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "../reducers/rootReducer";
import createSagaMiddleware from 'redux-saga';
import rootMiddleware from "../middlewares/rootMiddleware";

const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sagaMiddleware = createSagaMiddleware();

// Create the store
const store = createStore(
    rootReducer,
    storeEnhancers(applyMiddleware(sagaMiddleware))
);

// Run the root middleware which contains your sagas
sagaMiddleware.run(rootMiddleware);

export default store;

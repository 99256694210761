import { styled } from "@material-ui/core";
import React from "react";
import Utils from "../../helpers/Utils";

const HeaderWrapper = styled("div")(({ theme }) => ({
  textAlign: "center",
  marginBottom: "40px",

  "& .headerLogo": {
    maxHeight: "65px",
    objectFit: "cover",
    marginTop: "32px",
  },

  "& .headerTitle": {
    marginTop: 10,
  },
}));

const OnboardingHeader = () => {
  return (
    <HeaderWrapper>
      <img
        src="https://s3.us-east-1.amazonaws.com/pypepro/user/userLogo/14752-3GTi08E13P.png"
        alt="Logo"
        className="headerLogo"
      />

      <h4 className="headerTitle">Hi {Utils.getAccountData("fullName").split(" ")[0]}, Welcome to Pluto!</h4>
      <p className="headerDescription">
        We've created a handy checklist to get you up and running quickly.{" "}
        <br /> If you get stuck, help is just a click away!
      </p>
    </HeaderWrapper>
  );
};

export default OnboardingHeader;

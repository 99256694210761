import React from "react";
import { ContextProvider } from "./index";

const withContext = (Component) => {
  return function WrappedComponent(props) {
    return (
      <ContextProvider>
        <Component {...props} />
      </ContextProvider>
    );
  };
};

export default withContext;
import { Grid, IconButton, styled } from "@material-ui/core";
import React from "react";
import Title from "../common/Title";
import DealStageList from "./DealStageList";
import { CalenderIcon } from "../common/Icons";
import { useDispatch, useSelector } from "react-redux";
import { selectInsuranceDashboardState } from "../../../reducers/Insurance-dashboard/insuranceDashboardSelector";
import UserChip from "../common/UserChip";
import { fetchUserStageInfoStart } from "../../../reducers/Insurance-dashboard/insuranceDashboardSlice";
import {
  DATEPICKER_INITIAL_SETTING,
  FILTER_TYPE_TEAM,
  FILTER_TYPE_USER,
} from "../../../constants/CoreConstants";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import Utils from "../../../helpers/Utils";
import StageSkeleton from "../skeleton/StageSkeleton";
import BasicSelect from "../../../components/common/BasicSelect";
import BlurredDeal from "./BlurredDeal";
import DealStagePieChart from "./DealStagePieChart";

const DealStageWrapper = styled("div")(({ theme }) => ({
  padding: 24,
  backgroundColor: "#ffffff",
  border: "1px solid #E9EAEC",
  borderRadius: 12,

  "& .header": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 24,

    "& .actions": {
      display: "flex",
      alignItems: "center",
      gap: 8,

      "& .userWrap": {
        display: "flex",
        alignItems: "center",
        gap: 2,
        cursor: "pointer",
      },
    },
  },

  "& .dealSelect": {
    width: 350,
    marginLeft: "auto !important",
    marginRight: "14px !important",
  },
}));

const DealStage = () => {
  const dispatch = useDispatch();
  const {
    loadingUserStageInfoData,
    totalPipelineList,
    selectedPipeline,
    userStageInfoFilterType,
    userStageInfoStartDate,
    userStageInfoEndDate,
    stageInformation,
    stageInformationCount,
  } = useSelector(selectInsuranceDashboardState);

  const handleDateCallback = (start, end) => {
    let utcStartDate = start.format("YYYY-MM-DD");
    let utcEndDate = end.format("YYYY-MM-DD");

    let startDate = window.globalTimezoneConversionToDifferentTimezone(
      utcStartDate + " 00:00:00",
      Utils.getAccountData("userTimezoneRaw"),
      "UTC",
      "YYYY-MM-DD HH:mm:ss"
    );
    let endDate = window.globalTimezoneConversionToDifferentTimezone(
      utcEndDate + " 23:59:59",
      Utils.getAccountData("userTimezoneRaw"),
      "UTC",
      "YYYY-MM-DD HH:mm:ss"
    );
    dispatch(
      fetchUserStageInfoStart({
        userStageInfoStartDate: startDate,
        userStageInfoEndDate: endDate,
        userStageInfoFilterType: userStageInfoFilterType,
        selectedPipeline: selectedPipeline,
      })
    );
  };

  return (
    <DealStageWrapper className={"DealStage"}>
      <div className={`header ${stageInformationCount ? "empty" : ""}`}>
        <Title text="Deals Open By Current Stage" />

        <div className="actions">
          <BasicSelect
            name="pipeline"
            options={totalPipelineList}
            value={selectedPipeline}
            onChange={(e) => {
              dispatch(
                fetchUserStageInfoStart({
                  userStageInfoStartDate: userStageInfoStartDate,
                  userStageInfoEndDate: userStageInfoEndDate,
                  userStageInfoFilterType: userStageInfoFilterType,
                  selectedPipeline: e.target.value,
                })
              );
            }}
            mapping={{
              label: "title",
              value: "id",
            }}
            className={"dealSelect"}
          />

          <UserChip
            filterUser={userStageInfoFilterType}
            onClickAction={() => {
              dispatch(
                fetchUserStageInfoStart({
                  userStageInfoStartDate: userStageInfoStartDate,
                  userStageInfoEndDate: userStageInfoEndDate,
                  userStageInfoFilterType:
                    userStageInfoFilterType === FILTER_TYPE_USER
                      ? FILTER_TYPE_TEAM
                      : FILTER_TYPE_USER,
                  selectedPipeline: selectedPipeline,
                })
              );
            }}
          />

          <div>
            <DateRangePicker
              initialSettings={{
                ...DATEPICKER_INITIAL_SETTING,
                startDate: moment("09/01/2020"),
                endDate: moment(),
              }}
              onCallback={handleDateCallback}
            >
              <IconButton size="small">
                <CalenderIcon />
              </IconButton>
            </DateRangePicker>
          </div>
        </div>
      </div>

      {loadingUserStageInfoData ? (
        <StageSkeleton />
      ) : (
        <Grid container spacing={3}>
          {stageInformation[0] ? (
            <>
              <Grid item xs={12} lg={5}>
                <div
                  style={{
                    height: "100%",
                    width: "100%",
                    display: "grid",
                    placeItems: "center",
                  }}
                >
                  <DealStagePieChart />
                </div>
              </Grid>
              <Grid item xs={12} lg={7}>
                <DealStageList />
              </Grid>
            </>
          ) : (
            <BlurredDeal />
          )}
        </Grid>
      )}
    </DealStageWrapper>
  );
};

export default DealStage;

const rootUrl = `${process.env.REACT_APP_PYPEPRO_TENANT_BACKEND_SERVER}/${process.env.REACT_APP_TENANT_API_VERSION}/dashboard`

const insuranceOnboardUrl = `${process.env.REACT_APP_PYPEPRO_TENANT_BACKEND_SERVER}/${process.env.REACT_APP_TENANT_API_VERSION}/onboard`

const Api = {

    fetchInitialDashboardData: `${rootUrl}/get-initial-data`,

    userLeadCount: `${rootUrl}/user/lead-count`,
    teamLeadCount: `${rootUrl}/team/lead-count`,

    teamActiveDealCount: `${rootUrl}/team/active-deal-count`,
    userActiveDealCount: `${rootUrl}/user/active-deal-count`,

    teamAppointmentCount: `${rootUrl}/team/appointments-count`,
    userAppointmentCount: `${rootUrl}/user/appointments-count`,

    teamActivity: `${rootUrl}/team/activity`,
    userActivity: `${rootUrl}/user/activity`,

    teamUserStageInfo: `${rootUrl}/team/user-stage-info`,
    userUserStageInfo: `${rootUrl}/user/user-stage-info`,

    userSaleGoalStats: `${rootUrl}/sales-goal-stats`,
    announcements: `${rootUrl}/announcements`,

    onboardComplete: `${insuranceOnboardUrl}/mark-onboard-complete`,
    getOnboardData: `${insuranceOnboardUrl}/get-onboard-data`,
    markStepComplete: `${insuranceOnboardUrl}/mark-step-complete`,
    checkStepStatus: `${insuranceOnboardUrl}/check-step-status`,

    userActivities: `${rootUrl}/user-activities`,
    userInboxData: `${rootUrl}/inbox-data`,
    userNotifications: `${rootUrl}/notifications`,

}

export default Api;
import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { Box, makeStyles } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

const useStyle = makeStyles({
  paper: {
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "700px !important",
    },
  },
  closeButton: {
    color: " white !important",
    padding: "7px 12px 8px !important",
    fontWeight: "600 !important",
    borderRadius: "5px !important",
    textTransform: "capitalize !important",
    backgroundColor: " #316AFF !important",
  },
});

const OverViewVideoModal = ({ open, onClose, iframeVideoSrc }) => {
  const classes = useStyle();
  const [loading, setLoading] = useState(true);

  const handleIframeLoad = () => {
    setLoading(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.paper}
      >
        <Box minWidth={700} p={"16px"}>
          {loading ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "16px",
              }}
            >
              <Skeleton
                width={"100%"}
                height={315}
                variant="rectangular"
                style={{ borderRadius: "8px" }}
              />
            </div>
          ) : null}

          <div
            style={{
              width: "100%",
              display: loading ? "none" : "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "16px",
            }}
          >
            <iframe
              key={iframeVideoSrc}
              style={{ borderRadius: "8px" }}
              width="660"
              height="315"
              src={iframeVideoSrc}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
              onLoad={handleIframeLoad}
            ></iframe>
          </div>

          <Box display={"flex"} justifyContent={"end"} gridGap={16} mt={2}>
            <Button
              variant="contained"
              className={classes.closeButton}
              onClick={onClose}
            >
              Close
            </Button>
          </Box>
        </Box>
      </Dialog>
    </div>
  );
};

export default OverViewVideoModal;

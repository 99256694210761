export const fetchActiveDealCountReducers = {
    fetchActiveDealCountStart: (state, action) => {
        state.loadingActiveDealCount = true;

        state.totalActiveDealCountFilterType = action.payload.totalActiveDealCountFilterType;
        console.log(action.payload)
        if (action.payload.totalActiveDealStartDate && action.payload.totalActiveDealEndDate) {
            state.totalActiveDealStartDate = action.payload.totalActiveDealStartDate;
            state.totalActiveDealEndDate = action.payload.totalActiveDealEndDate;
        }
    },
    fetchActiveDealCountSuccess: (state, action) => {
        state.loadingActiveDealCount = false;
        state.totalActiveDealCount = action.payload.count
    },
    fetchActiveDealCountFail: (state, action) => {
        state.loadingActiveDealCount = false;
        state.error = action.payload;
    },
}
import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("md")]: {
      width: 230,
    },
    
  },
  tabHeader: {
    textAlign: "right"
  },
}));

const BuildingBasicTabs = () => {
  const classes = useStyles();

  return (
    <div className={`${classes.root} onboardingTabs`}>
      <div className={classes.tabHeader}>
        <img 
          src="https://d24gujj67p1uc1.cloudfront.net/assets/images/releases/1.0.0/lightning-bolt.png" 
          height={50} 
          alt="" 
        />
      </div>


    </div>
  );
};

export default BuildingBasicTabs;
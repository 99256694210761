import { styled } from "@material-ui/core";
import React from "react";
import SubTitle from "../../common/SubTitle";
import Title from "../../common/Title";


const EachGoalWrapper = styled("div")(({ theme, bgColor = "#ECF4FF" }) => ({
  padding: "0 8px",
  backgroundColor: bgColor,
  borderRadius: 12,

  display: "flex",
  justifyContent: "space-between",

  "& .GoalText": {
    padding: 18,

    "& .subTitle": {
      marginBottom: 4,
    },
  },

  "& .goalIcon": {
    height: 48,
    width: 48,
    backgroundColor: "white",

    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    border: "1px solid #DEE0E3",
    borderRadius: 12,
  },
}));

const GoalItem = ({ text, value, icon, bgColor }) => {
  return (
    <EachGoalWrapper bgColor={bgColor}>
      <div className="GoalText">
        <SubTitle text={text} className="subTitle" />
        <Title text={`${value}`} />
      </div>

      {icon && <div className="goalIcon">{icon}</div>}
    </EachGoalWrapper>
  );
};

export default GoalItem;

import React from "react";
import {Grid} from "@material-ui/core";
import {CallAddIcon, TalkTimeIcon} from "../common/Icons";
import Title from "../common/Title";
import SubTitle from "../common/SubTitle";
import {EachActivityWrapper} from "./index";

const formatTime = (inputSeconds) => {
    try {
        if (inputSeconds === undefined || inputSeconds === null || isNaN(inputSeconds)) {
            throw new Error("Invalid input");
        }
        inputSeconds = Number(inputSeconds);
        const days = Math.floor(inputSeconds / 86400); 
        const hours = Math.floor((inputSeconds % 86400) / 3600); 
        const minutes = Math.floor((inputSeconds % 3600) / 60); 
        const seconds = inputSeconds % 60; 
        
        const timeParts = [];
        if (days > 0) timeParts.push(`${days}d`);
        if (hours > 0) timeParts.push(`${hours}h`);
        timeParts.push(`${minutes}m`);
        timeParts.push(`${seconds}s`);
    
        return timeParts.join(' ');    
    } 
        catch (error) {
        return "0m 0s";
    }
}

const formatTotalCalls = (totalCalls) => {
    try {
        if (totalCalls === undefined || totalCalls === null || isNaN(totalCalls)) {
            throw new Error("Invalid input");
        }
        
        totalCalls = Number(totalCalls);

        if (totalCalls >= 1000000) {
            return (totalCalls / 1000000).toFixed(2) + 'M';
        } else if (totalCalls >= 1000) {
            return (totalCalls / 1000).toFixed(2) + 'k'; 
        } else {
            return totalCalls; 
        }
    } catch (error) {
        return "0";
    }
}



const Call = ({ activity }) => {
    return(
        <Grid container spacing={3}>
            <Grid item xs={12} lg={6}>
                <EachActivityWrapper bgColor={"#ECF4FF"}>
                    <div className="goalIcon">
                        <CallAddIcon />
                    </div>
                    <div className="GoalText">
                        <Title text={formatTotalCalls(activity?.totalCalls ?? 0)} />
                        <SubTitle text={"Total Calls"} className="subTitle" />
                    </div>
                </EachActivityWrapper>
            </Grid>
            <Grid item xs={12} lg={6}>
                <EachActivityWrapper bgColor={"#EEFFF5"}>
                    <div className="goalIcon">
                        <TalkTimeIcon />
                    </div>

                    <div className="GoalText">
                        <Title text={formatTime(activity?.totalTalkTime ?? 0)} />
                        <SubTitle text={"Total Talk Time"} className="subTitle" />
                    </div>
                </EachActivityWrapper>
            </Grid>
        </Grid>
    );
};

export default Call;
import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { Container, styled } from "@material-ui/core";
import TabContentSkeleton from "./TabContentSkeleton";

const GetStartedWrapper = styled(Container)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginBottom: 32,
}));

const OnboardingSkeleton = () => {
  return (
    <div
      style={{
          width: "100%",
          paddingBottom: "40px"
    }}
    >
      <GetStartedWrapper>
        <div
          style={{
            width: "100%",
            display: "flex",
            gap: "20px",
            marginBottom: "24px",
          }}
        >
            <div
                style={{
                    width: "30%",
                    display: "flex",
                    gap: "12px",
                    flexDirection: "column",
                    marginTop: "230px",
                    paddingTop: "12px"
                }}
            >
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "24px",
                        marginBottom: "10px",
                        justifyContent: "left",
                    }}
                >
                    <Skeleton variant="rect" width={"80%"} height={"20px"}/>
                    <Skeleton variant="circle" width={"50px"} height={"50px"}/>
                </div>
                <Skeleton
                    variant="rect"
                    width={"100%"}
                    height={"36px"}
                    style={{borderRadius: "6px"}}
                />
                <Skeleton
                    variant="rect"
                    width={"100%"}
                    height={"36px"}
                    style={{borderRadius: "6px"}}
                />
                <Skeleton
                    variant="rect"
                    width={"100%"}
                    height={"36px"}
                    style={{borderRadius: "6px"}}
                />
            </div>
            <div style={{width: "60%"}}>
                <TabContentSkeleton/>
            </div>
        </div>
      </GetStartedWrapper>
    </div>
  );
};

export default OnboardingSkeleton;

import React from "react";
import {Grid} from "@material-ui/core";
import {DealIcon} from "../common/Icons";
import Title from "../common/Title";
import SubTitle from "../common/SubTitle";
import {EachActivityWrapper} from "./index";

const formatTotalDealClosed = (totalDealClosed) => {
    try {
        if (totalDealClosed === undefined || totalDealClosed === null || isNaN(totalDealClosed)) {
            throw new Error("Invalid input");
        }

        totalDealClosed = Number(totalDealClosed);

        if (totalDealClosed >= 1000000) {
            return (totalDealClosed / 1000000).toFixed(2) + 'M';
        } else if (totalDealClosed >= 1000) {
            return (totalDealClosed / 1000).toFixed(2) + 'k';
        } else {
            return totalDealClosed;
        }
    } catch (error) {
        return "0";
    }
}


const Deal = ({ activity }) => {
    return(
        <Grid container spacing={3}>
            <Grid item xs={12} lg={12}>
                <EachActivityWrapper bgColor={"#ECF4FF"}>
                    <div className="goalIcon">
                        <DealIcon />
                    </div>
                    <div className="GoalText">
                        <Title text={formatTotalDealClosed(activity?.totalClosedDeals ?? 0)} />
                        <SubTitle text={"Total Deals Closed "} className="subTitle" />
                    </div>
                </EachActivityWrapper>
            </Grid>
        </Grid>
    );
};

export default Deal;
import React, { useState } from "react";
import { styled } from "@material-ui/core";
import Title from "../common/Title";
import AnnouncementDetailsModal from "./AnnouncementDetailsModal";
import { ANNOUNCEMENT_DATA } from "../../../constants/CoreConstants";

const ItemWrapper = styled("div")(({ theme }) => ({
 
  padding: 12,
  backgroundColor: "#FFFFFF",
  border: "1px solid #F4F6FB",
  borderRadius: 12,
  "&:hover": {
      backgroundColor: '#ECF4FF',
      cursor:'pointer'
    },
  "& .wrapper" : {
    display: "flex",
    alignItems: "center",
    gap: 16,
  },
  "& .imageWrapper": {
    height: 72,
    width: 84,
    "& .image": {
      height: "100%",
      width: "100%",
      borderRadius: 10,
      objectFit: "cover",
    },
  },

  "& .textWrapper": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    "& .title": {
      fontSize: 18,
      fontWeight: 600,
      lineHeight: "16px",
      marginBottom: "10px",
      marginTop:'7px'
    },

    "& .subtitle": {
      width: '430px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    "& .learnMore": {
      // fontFamily: "'Inter', sans-serif",
      fontWeight: 500,
      fontSize: 15,
      color: "#3E50F7",
    },
  },
}));

const processImageUrl = (eachItem) => {
  if (eachItem.fileType === ANNOUNCEMENT_DATA.FILE_TYPE_IMAGE) {
    return eachItem.imageUrl ? eachItem.imageUrl : ANNOUNCEMENT_DATA.DEFAULT_IMAGE;
  }

  return ANNOUNCEMENT_DATA.DEFAULT_VIDEO_IMAGE;
}

const EachAnnouncement = ({ item }) => {
  const [announcementDetailsModalOpen, setAnnouncementDetailsModalOpen] = useState(false);
  return (
    <ItemWrapper >
     <div className="wrapper" onClick={() => setAnnouncementDetailsModalOpen(true)}>
     <div className="imageWrapper">
        <img src={processImageUrl(item)} alt="ancmnt" className="image" />
      </div>
      <div className="textWrapper">
        <Title text={item.title} className="title" />

        <div className="learnMore">
          Learn more
        </div>
      </div>
     </div>
     {
          announcementDetailsModalOpen && (
            <AnnouncementDetailsModal
              open={announcementDetailsModalOpen}
              onClose={() => setAnnouncementDetailsModalOpen(false)}
              item={item}
            />
          )
        }
    </ItemWrapper>
  );
};

export default EachAnnouncement;

import moment from "moment";
import { DATEPICKER_INITIAL_SETTING } from "../constants/CoreConstants";
import { getCookie, getCookieLogin, setCookieLogin } from "./Cookie";

/**
 * Handle error exception
 * 
 * @param Error error 
 * @param Function/null callback 
 */
const handleException = (error, callback = null) => {
    console.error(error);

    // to do
}

/**
 * Get contact's display name
 * 
 * @param string name 
 * @param string number 
 * @param string email 
 * @returns string
 */
const getContactDisplayName = (name, number, email, full = false) => {
    if (name === '' || name === ' ' || name === null || name === 'null null') {
        if (number === '' || number === ' ' || number === null || number === 'null') {
            if (email === '' || email === ' ') {
                return '[No Name]';
            } else {
                return email.length > 15 && !full ? email.substr(0, 13) + ' ...' : email;
            }
        } else {
            return number;
        }
    }

    return name.length > 15 && !full ? name.substr(0, 13) + ' ...' : name;
}

/**
 * Display notification
 * 
 * @param string message 
 * @param string type 
 * @param string/null title 
 * @param boolean sticky 
 */
const showNotification = (message = 'Something went wrong', type = 'error') => {
    alert(message)
};

const countSms = (message) => {
    let charset7bit = ['£', '@', 'Â£', '$', 'Â¥', 'Ã¨', 'Ã©', 'Ã¹', 'Ã¬', 'Ã²', 'Ã‡', "\n", 'Ã˜', 'Ã¸', "\r", 'Ã…', 'Ã¥', 'Î”', '_', 'Î¦', 'Î“', 'Î›', 'Î©', 'Î ', 'Î¨', 'Î£', 'Î˜', 'Îž', 'Ã†', 'Ã¦', 'ÃŸ', 'Ã‰', ' ', '!', '"', '#', 'Â¤', '%', '&', "'", '(', ')', '*', '+', ',', '-', '.', '/', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', ':', ';', '<', '=', '>', '?', 'Â¡', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'Ã„', 'Ã–', 'Ã‘', 'Ãœ', 'Â§', 'Â¿', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', 'Ã¤', 'Ã¶', 'Ã±', 'Ã¼', 'Ã '];
    let charset7bitext = ["\f", '^', '{', '}', '\\', '[', '~', ']', '|', 'â‚¬'];
    let chars_arr = message.split("");
    let coding = '7bit';
    let parts = 1;
    let part = 1;
    let chars_used = 0;
    let chars_sms = 160;
    let charsLength = chars_arr.length;
    let part_chars_used;
    for (let i = 0; i < charsLength; i++) {
        if (charset7bit.indexOf(chars_arr[i]) >= 0) {
            chars_used = chars_used + 1;
        } else if (charset7bitext.indexOf(chars_arr[i]) >= 0) {
            chars_used = chars_used + 2;
        } else {
            coding = '16bit';
            chars_used = charsLength;
            break;
        }
    }
    if (coding === '7bit') {
        if (chars_used > 160) {
            parts = Math.ceil(chars_used / 153);
            part_chars_used = 7;
            chars_sms = 153;
            for (let i = 0; i < charsLength; i++) {
                if (part_chars_used + 1 > 160) {
                    part = part + 1;
                    part_chars_used = 7;
                }
                if (charset7bitext.indexOf(chars_arr[i]) >= 0 && part_chars_used + 2 > 160) {
                    part = part + 1;
                    part_chars_used = 7;
                }
                if (charset7bitext.indexOf(chars_arr[i]) === -1) {
                    part_chars_used = part_chars_used + 1;
                } else {
                    part_chars_used = part_chars_used + 2;
                }
            }
        }
        else {
            chars_sms = 160;
        }
    }
    else {
        if (chars_used > 70) {
            parts = Math.ceil(chars_used / 67);
            part_chars_used = 3;
            chars_sms = 67;
            for (let i = 0; i < charsLength; i++) {
                if (part_chars_used + 1 > 70) {
                    part = part + 1;
                    part_chars_used = 3;
                }
                part_chars_used = part_chars_used + 1;
            }
        }
        else {
            chars_sms = 70;
        }
    }
    return {
        sms: parts,
        length: chars_used,
        chars_per_sms: chars_sms,
    };
}

/**
 * Get initial of name
 * 
 * @param string name 
 * @returns string
 */
const getInitial = (name, initialCharacter = '') => {
    try {
        if (typeof name != 'undefined') {
            let fullNameInitial = initialCharacter
            if (name === 'null null' || name === '' || name === ' ') {
                fullNameInitial = initialCharacter;
            } else if (name !== 'undefined' || name !== null) {
                fullNameInitial = name.split(" ").map((n, i, a) => i === 0 || i + 1 === a.length ? n[0] : null).join("");
            }
            return fullNameInitial;
        } else {
            return initialCharacter;
        }
    } catch (error) {
        return initialCharacter;
    }
}

const abbreviateNumber = (n) => {
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
    if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
}

const getContactTitle = (contact) => {
    let contactName = contact['first_name'] + ' ' + contact['last_name']
    let contactTitle = (contactName.length > 1) ? contactName : contact['email'];

    return contactTitle.length > 1 ? contactTitle : contact['number'];
}

const generateTimezoneList = ($tzone = null) => {
    let timezone = [
        { 'EST': 'Eastern Time' },
        { 'America/Chicago': 'Central Time' },
        { 'America/Denver': 'Mountain Time' },
        { 'America/Phoenix': 'Arizona Time' },
        { 'America/Los_Angeles': 'Pacific Time' },
        { 'America/Anchorage': 'Alaska Time' },
        { 'Pacific/Honolulu': 'Hawaii Time' },
    ];

    if ($tzone) {

    }

    console.log(timezone);

    return timezone;
}

const generateRandomString = (length) => {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

/**
 * Function name: getAccountData
 * This function will provide
 * AuthUserAgencyName
 * packageType
 * starterPack
 * starterProPack
 * standardPack
 * baseUrl
 * userIsAgent
 * userIsOwner
 * userIsAdmin
 * button_text_transform_class
 * userTimezone
 * userTimezoneRaw
 * userId
 * agencyId
 * canvaApiKey
 * fullName
 * email
 * agencyLogo
 * profileImage
 * layoutSettings
 * centralBilling
*/
export const getAccountData = (needle) => {
    let accountData = null;
    let check_new = getCookie("encode__process__new")
    if (check_new !== undefined) {
        accountData = getCookieLogin(process.env.REACT_APP_ACCOUNT_DATA);
    } else {
        accountData = getCookie(process.env.REACT_APP_ACCOUNT_DATA);
    }
    // let accountData = getCookie(process.env.REACT_APP_ACCOUNT_DATA);
    if (accountData) {
        accountData = JSON.parse(accountData);
        return accountData[needle];
    }
    return false;
}

export const updateCookieData = (needle, value) => {

    let accountData = null;
    let check_new = getCookie("encode__process__new")
    if (check_new !== undefined) {
        accountData = getCookieLogin(process.env.REACT_APP_ACCOUNT_DATA);
    } else {
        accountData = getCookie(process.env.REACT_APP_ACCOUNT_DATA);
    }
    if (accountData) {
        accountData = JSON.parse(accountData);
        accountData[needle] = value;
    }
    setCookieLogin(process.env.REACT_APP_ACCOUNT_DATA, JSON.stringify(accountData), process.env.REACT_APP_ACCESS_TOKEN_VALIDITY);
}


const convertColorCodeToRGBA = (hexCode, opacity) => {
    let hex = hexCode.replace('#', '');

    if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }

    let r = parseInt(hex.substring(0, 2), 16),
        g = parseInt(hex.substring(2, 4), 16),
        b = parseInt(hex.substring(4, 6), 16);

    return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity + ')';
}

const getFiltersStoredData = () => {
    let filterUser = localStorage.getItem('dashboardFilterUser');
    let dateFilter = localStorage.getItem('dashboardDateFilter');
    if (!filterUser) {
        filterUser = true;
        localStorage.setItem('dashboardFilterUser', filterUser);
    }
    if (!dateFilter) {
        dateFilter = 'This Month';
        localStorage.setItem('dashboardDateFilter', dateFilter);
    }

    let dates = DATEPICKER_INITIAL_SETTING.ranges[dateFilter];

    return {
        filterUser: filterUser === "true",
        startDate: dates[0],
        endDate: dates[1]
    }
}

export const storeFiltersDataToLocalStorage = (dateLabels) => {
    if (dateLabels && dateLabels !== 'Custom Range') {
        localStorage.setItem('dashboardDateFilter', dateLabels);
    }
}

const checkLetterExistInText = (text) => {
    var regExp = /[^0-9]+/g;
    let result = false
    if (regExp.test(text)) {

        result = true
    }
    return result
}

const isEmpty = (text) => {
    if (text !== undefined && text !== null && text !== "null" && text !== "") {
        return false;
    }
    return true;
}

const isValidURL = (url) => {
    const pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
        "i"
    ); // fragment locator
    return !!pattern.test(url);
};


// const isValidURL = (string) => {
//     const res = string.match(/^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/);
//     return (res !== null);
// };

const isValidEmail = (email) => {
    //eslint-disable-next-line
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

const isValidMobileNumber = (mobileNumber) => {

    if (mobileNumber !== '' && mobileNumber.length < 10) {
        return false;
    } else if (mobileNumber !== '' && mobileNumber.length > 11) {
        return false;
    } else if (mobileNumber.length === 11 && mobileNumber.substring(0, 1) !== '1') {
        return false;
    }
    return true;
}

const isOnlyNumber = (value) => {
    const reg = /^\d+$/;
    return reg.test(value);
}
function formatCurrency(n, currency = "") {
    if (typeof n !== "number" || isNaN(n)) return currency + Number(0).toFixed(2);
    const hasDecimal = n % 1 !== 0;

    return (
        currency +
        n.toFixed(hasDecimal ? 2 : 0).replace(/./g, function (c, i, a) {
            return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
        })
    );
}

const formatDate = (rawDate, format = "MM-DD-YYYY") => {
    try {
        return moment(rawDate).format(format);
    } catch (err) {
        console.log(err)
        return rawDate;
    }
};

const toZero = (val) => {
    try {
        if (val !== undefined && val !== null && typeof val === "number") {
            return val;
        }

        return 0;
    } catch (err) {
        return 0;
    }
};

const numberArt = (val, nullReplacer = "", suffix = "", abs = false) => {
    if (val === null && nullReplacer) {
        return nullReplacer;
    }

    try {
        if (val !== undefined && typeof val === "number") {
            if (abs) {
                val = Math.abs(val);
            }

            if (suffix) {
                val = val + suffix;
            }

            return val;
        }

        return 0;
    } catch (err) {
        return 0;
    }
};

const startDateConvert = (time) => {
  if (time) {
    const startDate = time.format("YYYY-MM-DD");
    return window.globalTimezoneConversionToDifferentTimezone(
      startDate + " 00:00:00",
      Utils.getAccountData("userTimezoneRaw"),
      "UTC",
      "YYYY-MM-DD HH:mm:ss"
    );
  } else {
    return null;
  }
};

const endDateConvert = (time) => {
    if (time) {
      const endDate = time.format("YYYY-MM-DD");
      return window.globalTimezoneConversionToDifferentTimezone(
        endDate + " 23:59:59",
        Utils.getAccountData("userTimezoneRaw"),
        "UTC",
        "YYYY-MM-DD HH:mm:ss"
      );
    } else {
      return null;
    }
  };

/**
 * Utility helper
 */
const Utils = {
    handleException,
    getContactDisplayName,
    getInitial,
    showNotification,
    abbreviateNumber,
    getContactTitle,
    generateTimezoneList,
    countSms,
    generateRandomString,
    getAccountData,
    convertColorCodeToRGBA,
    getFiltersStoredData,
    storeFiltersDataToLocalStorage,
    checkLetterExistInText,
    isEmpty,
    isValidURL,
    isOnlyNumber,
    isValidEmail,
    isValidMobileNumber,
    formatCurrency,
    formatDate,
    toZero,
    numberArt,
    updateCookieData,
    startDateConvert,
    endDateConvert
}

export default Utils;
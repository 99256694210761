import * as actionTypes from "./types";

const contextActions = (dispatch) => {
  return {
    setRecommended: (recommended) => {
      dispatch({ type: actionTypes.SET_RECOMMENDED, payload: recommended });
    },
    updateRecommended: (value, key, index) => {
      dispatch({ type: actionTypes.UPDATE_RECOMMENDED, payload: { value, key, index } });
    },
    setOptional: (optional) => {
      dispatch({ type: actionTypes.SET_OPTIONAL, payload: optional });
    },
    updateOptional: (value, key, index) => {
      dispatch({ type: actionTypes.UPDATE_OPTIONAL, payload: { value, key, index } });
    },
    setIsOnboardCompleted: (status) => {
      dispatch({ type: actionTypes.SET_ONBOARD_STATUS, payload: status });
    },
  };
};

export default contextActions;

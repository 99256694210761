import React, { useState } from "react"
import BootstrapTooltip from "../../globals/BootstrapTooltip";
import HelpIcon from "@material-ui/icons/Help";

let registerStadtardPdf = `${process.env.REACT_APP_CDN_LINK}asset/images/releases/1.0.0/standard-lowvolume.pdf`;

const GerenalStep = ({data, handleUpdateData, handleStep}) => {
    const [businessName, setBusinessName] = useState(data.customer_name)
    const [streetOne, setStreetOne] = useState(data.street)
    const [city, setCity] = useState(data.city)
    const [state, setState] = useState(data.region)
    const [zip, setZip] = useState(data.postal_code)

    const handleField = (value, field) => {
        if(field === 'businessName'){
            setBusinessName(value)
            handleUpdateData('general', 'customer_name', value)
        }else if(field === "streetOne"){
            setStreetOne(value)
            handleUpdateData('general', 'street', value)
        }else if(field === "city"){
            setCity(value)
            handleUpdateData('general', 'city', value)
        }else if(field === "state"){
            setState(value)
            handleUpdateData('general', 'region', value)
        }else if(field === "zip"){
            if(value === ""){
                setZip("")
                handleUpdateData('general', 'postal_code', "")
                return
            }            
            let isnum = /^\d+$/.test(value);
            if(!isnum){
                return
            }
            setZip(value)
            handleUpdateData('general', 'postal_code', value)
        }
    }

    const handleNextClick = () => {
        if(
            businessName.trim() === "" || 
            streetOne.trim() === "" || 
            city.trim() === "" ||
            state.trim() === "" ||
            zip.trim() === ""
        ){
            if(window.showNotification!== undefined){
                window.showNotification("ERROR", "Fill up the form correctly");
            }
            return
        }
        handleStep()
    }

    return(
        <div className="twilio___migration__gerenal_step twili__migration___container">
            <div className="row" style={{ position: "relative" }}>
                <div className="col s12">
                    <div style={{ position: "absolute", right: "12px", border: "1px solid #dd1616", borderRadius: "4px", padding: "2px"}}>
                        <BootstrapTooltip arrow title={"Standard A2P Registration"}>
                            <a href={registerStadtardPdf} target="_blank" rel="noreferrer" className="ml-2" style={{ display: "flex", alignItems: "center", fontSize: "14px", color: "#dd1616", gap: "2px" }}>
                                Get Help
                                <HelpIcon style={{ color: "#dd1616" }}/>
                            </a>
                        </BootstrapTooltip>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col s12">
                    <h6 className="twilio__label__v2 text-dark-blue m-0 text-left mb-2">Legal Business Name *</h6>
                    <input 
                        value={businessName} 
                        onChange={ (e) => handleField(e.target.value, 'businessName')} 
                        type="text" 
                        placeholder="Enter business name"
                        className="modal_bordered_text_field radius-5 white"
                    />
                    <p className="twilio__migration__hints"> <span class="business-name-span">Enter the exact legal business name, as registered with the EIN. E.g. Twilio Inc. rather than Twilio</span></p>
                </div>
            </div>

            <div className="row">
                <div className="col s12">
                    <h6 className="twilio__label__v2 text-dark-blue m-0 text-left mb-2">Business Street Address *</h6>
                    <input 
                        value={streetOne} 
                        onChange={ (e) => handleField(e.target.value, 'streetOne')} 
                        type="text" 
                        placeholder="Enter business street address"
                        className="modal_bordered_text_field radius-5 white"
                    />
                </div>
            </div>

            <div className="row">
                <div className="col s12">
                    <h6 className="twilio__label__v2 text-dark-blue m-0 text-left mb-2">City *</h6>
                    <input 
                        value={city} 
                        onChange={ (e) => handleField(e.target.value, 'city')} 
                        type="text" 
                        placeholder="Enter city"
                        className="modal_bordered_text_field radius-5 white"
                    />
                </div>
            </div>

            <div className="row">
                <div className="col s12">
                    <h6 className="twilio__label__v2 text-dark-blue m-0 text-left mb-2">State *</h6>
                    <input 
                        value={state} 
                        onChange={ (e) => handleField(e.target.value, 'state')} 
                        type="text" 
                        placeholder="Enter state"
                        className="modal_bordered_text_field radius-5 white"
                    />
                </div>
            </div>

            <div className="row">
                <div className="col s12">
                    <h6 className="twilio__label__v2 text-dark-blue m-0 text-left mb-2">Zip code *</h6>
                    <input 
                        value={zip} 
                        onChange={ (e) => handleField(e.target.value, 'zip')} 
                        type="text" 
                        placeholder="Enter zip code"
                        className="modal_bordered_text_field radius-5 white"
                    />
                </div>
            </div>

            <div className="row">
                <div 
                    className="col s12 twilio__migration__submit__btn" 
                    style={(
                        businessName.trim() === "" || 
                        streetOne.trim() === "" || 
                        city.trim() === "" ||
                        state.trim() === "" ||
                        zip.trim() === ""
                    ) ? {'background': '#9e9e9e', 'cursor': 'not-allowed'} : {}} 
                    onClick={handleNextClick}
                >
                    Next
                </div>
            </div>
        </div>
    )
}
export default GerenalStep;
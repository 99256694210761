import React from "react";
import { Grid } from "@material-ui/core";
import { EmailIcon } from "../common/Icons";
import Title from "../common/Title";
import SubTitle from "../common/SubTitle";
import { EachActivityWrapper } from "./index";

const formatTotalEmails = (totalEmails) => {
    try {
        if (totalEmails === undefined || totalEmails === null || isNaN(totalEmails)) {
            throw new Error("Invalid input");
        }

        totalEmails = Number(totalEmails);

        if (totalEmails >= 1000000) {
            return (totalEmails / 1000000).toFixed(2) + 'M';
        } else if (totalEmails >= 1000) {
            return (totalEmails / 1000).toFixed(2) + 'k';
        } else {
            return totalEmails;
        }
    } catch (error) {
        return "0";
    }
}


const Email = ({ activity }) => {
    return (
        <Grid container spacing={3}>
            <Grid item xs={12} lg={6}>
                <EachActivityWrapper bgColor={"#ECF4FF"}>
                    <div className="goalIcon">
                        <EmailIcon />
                    </div>
                    <div className="GoalText">
                        <Title text={formatTotalEmails(activity?.totalSent ?? 0)} />
                        <SubTitle text={"Total Sent"} className="subTitle" />
                    </div>
                </EachActivityWrapper>
            </Grid>
            <Grid item xs={12} lg={6}>
                <EachActivityWrapper bgColor={"#EEFFF5"}>
                    <div className="goalIcon">
                        <EmailIcon />
                    </div>
                    <div className="GoalText">
                        <Title text={formatTotalEmails(activity?.totalReceive ?? 0)} />
                        <SubTitle text={"Total Received"} className="subTitle" />
                    </div>
                </EachActivityWrapper>
            </Grid>
        </Grid>
    );
};

export default Email;

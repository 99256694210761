import React, { useEffect } from "react";
import { Grid, styled } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { selectInsuranceDashboardState } from "../../reducers/Insurance-dashboard/insuranceDashboardSelector";
import { fetchDashboardInitialDataStart } from "../../reducers/Insurance-dashboard/insuranceDashboardSlice";
import Activity from "./activity";
import Announcements from "./announcements";
import DailyGoal from "./dailyGoal";
import DashboardCards from "./dashboardCards";
import DealStage from "./dealStage";
import MonthlyGoal from "./monthlyGoal";
import InsuranceDashboardSkeleton from "./skeleton/InsuranceDashboardSkeleton";
import GoalNotSelected from "./goalNotSelected/GoalNotSelected";

const Wrapper = styled("div")(({ theme }) => ({
  position: "relative",
}));

const InsuranceDashboard = () => {
  const dispatch = useDispatch();

  const { loadingDashboard, initialData, selectedGoal } = useSelector(
    selectInsuranceDashboardState
  );

  useEffect(() => {
    dispatch(fetchDashboardInitialDataStart());
  }, [dispatch]);

  return (
    <Wrapper>
      {!loadingDashboard && initialData ? (
        <>
          <Grid container spacing={3}>
            {selectedGoal === null ? (
              <GoalNotSelected />
            ) : (
              <>
                <Grid item xs={12} md={12} lg={8}>
                  <DailyGoal />
                </Grid>
                <Grid item xs={12} md={12} lg={4}>
                  <MonthlyGoal />
                </Grid>
              </>
            )}

            <Grid item xs={12}>
              <DashboardCards />
            </Grid>

            <Grid item xs={12}>
              <DealStage />
            </Grid>

            <Grid item xs={12} lg={6}>
              <Activity />
            </Grid>
            <Grid item xs={12} lg={6}>
              <Announcements />
            </Grid>
          </Grid>
        </>
      ) : (
        <InsuranceDashboardSkeleton />
      )}

      {/* <DashboardActions /> */}
    </Wrapper>
  );
};

export default InsuranceDashboard;

import React from "react";
import { styled } from "@material-ui/core";
import { StarIcon, TargetIcon } from "../../common/Icons";
import GoalItem from "./GoalItem";


const GoalListWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: 24,
}));

const GoalList = () => {



  return (
    <GoalListWrapper className="goalList">
      <GoalItem
        text="STARTED TODAY AT"
        value={"$23525"}
        icon={<StarIcon />}
        bgColor={"#ECF4FF"}
      />
      <GoalItem
        text="SHOULD BE AT"
        value={"$4325"}
        icon={<TargetIcon />}
        bgColor={"#EEFFF5"}
      />
      {/* <GoalItem
        text="GOAL FOR TODAY"
        value={"$8525"}
        icon={<AchievedIcon />}
        bgColor={"#F4F6FB"}
      /> */}
    </GoalListWrapper>
  );
};

export default GoalList;

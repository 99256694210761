export const fetchUserCountDataReducers = {
    fetchLeadCountStart: (state, action) => {
        state.loadingLeadCount = true;
        state.totalLeadCountFilterType = action.payload.totalLeadCountFilterType;
        if (action.payload.totalLeadCountStartDate && action.payload.totalLeadCountEndDate) {
            state.totalLeadCountStartDate = action.payload.totalLeadCountStartDate;
            state.totalLeadCountEndDate = action.payload.totalLeadCountEndDate;
        }
    },
    fetchLeadCountSuccess: (state, action) => {
        state.loadingLeadCount = false;
        state.totalLeadCount = action.payload.count
    },
    fetchLeadCountFail: (state, action) => {
        state.loadingLeadCount = false;
        state.error = action.payload;
    },
}
import { styled } from "@material-ui/core";
import React, { Fragment } from "react";
import Title from "../common/Title";
import BasicTab from "../common/BasicTab";
import SubTitle from "../common/SubTitle";
import { PercentArrowDownIcon } from "../common/Icons";
import { useDispatch, useSelector } from "react-redux";
import { selectInsuranceDashboardState } from "../../../reducers/Insurance-dashboard/insuranceDashboardSelector";
import {
  DASHBOARD_GOAL_TABS,
  FREQUENCY,
  GOAL_TYPE_SALES,
} from "../../../constants/CoreConstants";
import Utils from "../../../helpers/Utils";
import { fetchUserSalesGoalStart } from "../../../reducers/Insurance-dashboard/insuranceDashboardSlice";
import MonthlyGoalSkeleton from "../skeleton/MonthlyGoalSkeleton";

const MonthlyGoalWrapper = styled("div")(({ theme }) => ({
  padding: 24,
  backgroundColor: "#ffffff",
  border: "1px solid #E9EAEC",
  borderRadius: 12,

  "& .header": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 28,

    "& .dateLabel": {
      fontSize: "24px !important",
      margin: 0,
    },
    "& .dateSmall": {
      fontSize: "14px",
      display: "block",
    },
  },

  "& .contentWrapper": {
    display: "flex",
    flexDirection: "column",
    gap: 16,

    "& .trending": {
      padding: 16,
      borderRadius: 12,
      border: "1px solid #E9EAEC",

      display: "flex",
      justifyContent: "space-between",
      boxShadow: "0px 1px 2px 0px #14151A0D",

      "& .GoalText": {
        padding: "8px",

        "& .title": {
          marginBottom: 4,
        },
      },

      "& .trendingTitle": {
        color: "#7E869A",
        fontWeight: 500,
      },
    },

    "& .lastMonth": {
      padding: 16,
      borderRadius: 12,
      backgroundColor: "#F7F7F8",
      borderTop: "2px solid #3e50fe",

      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      gap: 24,
      flexWrap: "wrap",

      "& .percentAchieved": {
        backgroundColor: "#fff",
        borderRadius: 12,
        padding: 18,

        display: "flex",
        flexDirection: "column",

        "& .titleWrapper": {
          display: "flex",
          alignItems: "center",
          gap: 8,
        },

        "& .subTitle": {
          color: "#7E869A",
          fontSize: 14,
        },
      },
      "& .lastAchieved": {
        display: "flex",
        flexDirection: "column",
        gap: 12,
        marginRight: 24,
      },
    },
    "& .currentGoal": {
      padding: 16,
      borderRadius: 12,
      backgroundColor: "#F7F7F8",
      borderTop: "2px solid #ff0055",

      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      gap: 24,
      flexWrap: "wrap",

      "& .percentAchieved": {
        backgroundColor: "#fff",
        borderRadius: 12,
        padding: 18,

        display: "flex",
        flexDirection: "column",

        "& .titleWrapper": {
          display: "flex",
          alignItems: "center",
          gap: 8,
        },

        "& .subTitle": {
          color: "#7E869A",
          fontSize: 14,
        },
      },
      "& .lastAchieved": {
        display: "flex",
        flexDirection: "column",
        gap: 12,
        marginLeft: 16,
      },
    },
  },
}));

const MonthlyGoal = () => {
  const dispatch = useDispatch();
  const {
    goalStatFilter,
    currentFrequencyPredictedSale,
    trendingForText,
    currentVsLastSalesGrowth,
    currentFrequencyTotalSale,
    currentGoalPercentage,
    previousGoalTotalSale,
    currentMonth,
    currentFrequencyStartDate,
    currentFrequencyEndDate,
    currentYear,
    frequency,
    userSaleGoalId,
    loadingUserSalesGoalData,
    selectedGoal
  } = useSelector(selectInsuranceDashboardState);

  const formatCurrency = (value) => {
    if (typeof value !== "number" || isNaN(value) || selectedGoal === null) return "N/A";

    if (goalStatFilter === GOAL_TYPE_SALES) {
      return "$" + Utils.formatCurrency(Utils.abbreviateNumber(value)) || "0.00";
    }

    return Utils.formatCurrency(Utils.toZero(value));
  };

  const formatPercentage = (value) => {
    if (typeof value !== "number" || isNaN(value) || selectedGoal === null) return "N/A";

    return Utils.numberArt(value, "N/A", "%", true);
  };

  const frequencyLabel = () => {
    switch (frequency) {
      case FREQUENCY.WEEKLY:
        return "WEEK";
      case FREQUENCY.MONTHLY:
        return "MONTH";
      case FREQUENCY.QUARTERLY:
        return "QUARTER";
      case FREQUENCY.YEARLY:
        return "YEAR";
      default:
        return "N/A";
    }
  };

  const statsLabel = () => {
    switch (frequency) {
      case FREQUENCY.WEEKLY:
        return (
          <p className="dateLabel">
            This Week Stats
            <span className="dateSmall">
              {" "}
              [{Utils.formatDate(currentFrequencyStartDate, "MMM D")} -{" "}
              {Utils.formatDate(currentFrequencyEndDate, "MMM D")}]
            </span>
          </p>
        );
      case FREQUENCY.MONTHLY:
        return currentMonth + " Stats";
      case FREQUENCY.QUARTERLY:
        return (
          <p className="dateLabel">
            This Quarter Stats
            <span className="dateSmall">
              {" "}
              [{Utils.formatDate(currentFrequencyStartDate, "MMM D")} -{" "}
              {Utils.formatDate(currentFrequencyEndDate, "MMM D")}]
            </span>
          </p>
        );
      case FREQUENCY.YEARLY:
        return currentYear + " Stats";
      default:
        return "N/A";
    }
  };

  return (
    <MonthlyGoalWrapper className="monthlyGoal">
      {loadingUserSalesGoalData ? (
        <MonthlyGoalSkeleton />
      ) : (
        <Fragment>
          <div className="header">
            <Title text={statsLabel()} />
            <BasicTab
              list={DASHBOARD_GOAL_TABS}
              activeTab={goalStatFilter}
              onActiveTab={(id) => {
                dispatch(
                  fetchUserSalesGoalStart({
                    userSaleGoalId: userSaleGoalId,
                    goalStatFilter: id,
                  })
                );
              }}
            />
          </div>

          <div className="contentWrapper">
            <div className="trending">
              <div className="GoalText">
                <Title
                  className="title"
                  text={formatCurrency(currentFrequencyPredictedSale)}
                />
                <SubTitle text={trendingForText || <div style={{padding:"10px"}}> </div>} className="subTitle" />
              </div>
              <div>
                <SubTitle text={"TRENDING"} className="trendingTitle" />
              </div>
            </div>

            <div className="lastMonth">
              <div className="percentAchieved">
                <div className="titleWrapper">
                  <Title text={formatPercentage(currentVsLastSalesGrowth)} />
                  {currentVsLastSalesGrowth !== null ? (
                    currentVsLastSalesGrowth > 100 ? (
                      <PercentArrowDownIcon
                        color="#33f28b"
                        style={{ transform: "rotate(180deg)" }}
                      />
                    ) : (
                      <PercentArrowDownIcon />
                    )
                  ) : null}
                </div>

                <SubTitle
                  text={`VS LAST ${frequencyLabel()}`}
                  className="subTitle"
                />
              </div>
              <div className="lastAchieved">
                <Title text={formatCurrency(previousGoalTotalSale)} />
                <SubTitle
                  text={`LAST ${frequencyLabel()}`}
                  className="subTitle"
                />
              </div>
            </div>

            <div className="currentGoal">
              <div className="lastAchieved">
                <Title text={formatCurrency(currentFrequencyTotalSale)} />
                <SubTitle text={"CURRENTLY AT"} className="subTitle" />
              </div>
              <div className="percentAchieved">
                <div className="titleWrapper">
                  <Title
                    text={
                      formatPercentage(currentGoalPercentage) ||
                      Utils.numberArt(
                      currentGoalPercentage,
                      "N/A",
                      "%",
                      true
                    )}
                  />
                  {currentGoalPercentage !== null && selectedGoal ? (
                    currentGoalPercentage > 100 ? (
                      <PercentArrowDownIcon
                        color="#33f28b"
                        style={{ transform: "rotate(180deg)" }}
                      />
                    ) : (
                      <PercentArrowDownIcon />
                    )
                  ) : null}
                </div>

                <SubTitle text={"OF GOAL"} className="subTitle" />
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </MonthlyGoalWrapper>
  );
};

export default MonthlyGoal;

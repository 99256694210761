import React from "react";
import {Grid} from "@material-ui/core";
import {AppointmentIcon} from "../common/Icons";
import Title from "../common/Title";
import SubTitle from "../common/SubTitle";
import {EachActivityWrapper} from "./index";

const Appointment = ({ activity }) => {
    return(
        <Grid container spacing={3}>
            <Grid item xs={12} lg={12}>
                <EachActivityWrapper bgColor={"#ECF4FF"}>
                    <div className="goalIcon">
                        <AppointmentIcon />
                    </div>
                    <div className="GoalText">
                        <Title text={activity?.totalAppointments ?? 0} />
                        <SubTitle text={"Total Appointment"} className="subTitle" />
                    </div>
                </EachActivityWrapper>
            </Grid>
        </Grid>
    );
};

export default Appointment;
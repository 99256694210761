export const fetchActivityReducers = {
    fetchActivityStart: (state, action) => {
        state.loadingActivityData = true;

        state.activityFilterType = action.payload.activityFilterType;
        state.selectedActivity = action.payload.selectedActivity;
        if (action.payload.activityStartDate && action.payload.activityEndDate) {
            state.activityStartDate = action.payload.activityStartDate;
            state.activityEndDate = action.payload.activityEndDate;
        }
    },
    fetchActivitySuccess: (state, action) => {
        state.loadingActivityData = false;
        state.activity = action.payload;
    },
    fetchActivityFail: (state, action) => {
        state.loadingActivityData = false;
        state.error = action.payload;
    },
}
import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { HistoryProvider } from "../../../App";
import BlurredPieChart from "./BlurredPieChart";
import BlurredDealStageList from "./BlurredDealStageList";
import { useSelector } from "react-redux";
import { selectInsuranceDashboardState } from "../../../reducers/Insurance-dashboard/insuranceDashboardSelector";

const useStyles = makeStyles((theme) => ({
  gridWrapper: {
    position: "relative",

    "& .blurred": {
      filter: "blur(7px)",
      "-webkit-filter": "blur(7px)",
    },

    "& .action": {
      position: "absolute",
      top: "200px",
      left: "58%",
      transform: "translate(-50%, -50%)",
      fontSize: "20px",
      fontWeight: "bold",
    },

    "& .text": {
      fontSize: 24,
      textAlign: "left",
      fontWeight: 600,
      lineHeight: "32px",
      color: "rgb(0, 0, 0)",
      margin: 0,

      "& .actionButton": {
        marginTop: "15px",
        backgroundColor: "#3e50fe",
        padding: "8px 16px",
        borderRadius: 4,
        color: "#fff",
        "&:hover, &:focus": {
          backgroundColor: "#3e50fe",
        },
      },
    },
  },
}));

const BlurredDeal = () => {
  const classes = useStyles();
  const history = useContext(HistoryProvider);

  const { selectedPipeline } = useSelector(selectInsuranceDashboardState);

  return (
    <Grid item xs={12} className={classes.gridWrapper}>
      <Grid container spacing={3} className="blurred">
        <Grid item xs={12} md={12} lg={5}>
          <BlurredPieChart />
        </Grid>
        <Grid item xs={12} md={12} lg={7}>
          <BlurredDealStageList />
        </Grid>
      </Grid>

      <div className="action">
        <h6 className="text">
          Add a new lead to your pipeline to <br /> activate this section.
          <br />
          <Link
            to={`/deal-stages?id=${selectedPipeline}`}
            onClick={(e) => {
              e.preventDefault();
              history.push(`/deal-stages?id=${selectedPipeline}`);
            }}
          >
            <Button
              variant="contained"
              color="primary"
              className="actionButton"
              disableElevation
            >
              Get Started
            </Button>
          </Link>
        </h6>
      </div>
    </Grid>
  );
};

export default BlurredDeal;

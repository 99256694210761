import {makeStyles} from "@material-ui/core/styles";

const useNotificationPopUpStyles = makeStyles((theme) => ({
  modalWrapper: {
    display: 'grid',
    placeContent: 'center',
  },
  modalInner: {
    position: 'relative',
    '&:focus-visible': {
      border: 'none !important',
      boxShadow: 'none !important',
      outline: 'none !important',
    },
  },
  headerWrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: 0,
  },
  headerInner: {
    padding: '15px',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  headerTitle: {
    margin: 0,
    paddingTop: '10px',
    padding: 0,
    textAlign: 'center',
    color: '#fbfbfb',
    fontSize: '23px',
  },
  headerSubTitle: {
    margin: 0,
    marginBottom: '.5rem',
    padding: 0,
    textAlign: 'center',
    color: '#fbfbfb',
    fontSize: '16px',
    paddingTop: '10px',
    paddingBottom: '10px'
  },
  modalBody: {
    marginTop: '35px',
  },
  bodyContainer: {
    padding: '0 14px 24px',
    overflowY: 'auto',
    width: '590px',
    '&::-webkit-scrollbar': {
      width: '6px',
      height: '6px',
      scrollbarWidth: 'thin',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '10px',
      WebkitBoxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
      boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
      backgroundColor: '#949db2',
      boxSizing: 'border-box',
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: '4px',
      background: '#f4f5f7',
      boxShadow: 'inset 1px 0px 0px rgba(148, 157, 178, 0.12)',
      WebkitBoxShadow: 'inset 1px 0px 0px rgba(148, 157, 178, 0.12)',
    },
  },
  closeCircleIcon: {
    position: 'absolute',
    right: '15px',
    top: '15px',
    '& svg': {
      width: '25px',
      height: '25px',
      borderRadius: '50px',
      cursor: 'pointer',
    },
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '25px',
  },
  bodyTextContainer: {
    padding: '20px',
  },
  messageTitle: {
    color: "#36454F",
    display: "block",
    padding: "10px",
    fontSize: "12px",
    textAlign: "justify",
    marginBottom: "10px",
    marginTop: "20px",
    borderRadius: "4px"
  },
  failedReason: {
    color: "red",
    fontStyle: "italic",
    fontWeight: 600
  },
  textStrong: {
    fontWeight: 'bold',
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '5px'
  },
  actionButton: {
    color: "rgb(244, 245, 236)",
    display: "inline-block",
    padding: "10px 15px 8px",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "24px",
    cursor: "pointer",
    background: "rgb(60, 126, 243)",
    border: "1px solid rgb(60, 126, 243)",
    height: "48px",
    width: "100%",
    borderRadius: "4px",
    marginTop: "10px",
    textAlign: "center",
    "&:hover": {
      opacity: 0.9,
    },
  },
  riskActionButton: {
    backgroundColor: '#e11f1f',
    padding: '15px 30px',
    color: '#fff',
    borderRadius: '4px',
    fontSize: '14px',
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.9,
    },
  },
  reasonTitle: {
    padding: '10px',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '17px',
  },
  announcement: {
    padding: "10px",
    backgroundColor: "red",
    color: "white",
    marginBottom: "10px",
    fontWeight: "bold"
  },
  zoomInZoomOut: {
    animation: '$zoomInZoomOut 2s ease-out infinite',
  },
  emailInput: {
    width: "100% !important",
    height: "48px !important",
    marginTop: "4px !important",
    marginBottom: "0px !important",
    border: "1px solid rgb(141, 148, 165) !important",
    boxSizing: "border-box !important",
    borderRadius: "4px !important",
    fontSize: "16px !important",
    padding: "12px !important",
    lineHeight: "24px !important",
    fontWeight: "normal !important",
    textAlign: "center"
  },
  checkbox: {
    opacity: "initial !important",
    pointerEvents: "initial !important",
    position: "initial !important",
    padding: "initial !important",
    border: "initial !important",
  },
  consent: {
    display: "flex",
    gap: "10px",
  },
  footer: {
    padding: "0px 10px 20px 35px"
  },
  errorMessage: {
    marginTop: "4px",
    display: "block",
    fontSize: "12px",
    color: "red",
  },
  '@keyframes zoomInZoomOut': {
    '0%': {
      transform: 'scale(1, 1)',
    },
    '50%': {
      transform: 'scale(1.1, 1.1)',
    },
    '100%': {
      transform: 'scale(1, 1)',
    },
  },
  headerBg: {
    background: '#ff1111'
  },
  runningBorder: {
    border: '1px solid',
    borderImage: 'linear-gradient(45deg, red, blue, green, yellow) 1',
    animation: '$borderAnimation 5s linear infinite'
  },
  '@keyframes borderAnimation': {
    '0%': {
      borderImageSlice: 1,
      borderImage: 'linear-gradient(45deg, red, blue, green, yellow) 1',
    },
    '100%': {
      borderImageSlice: 1,
      borderImage: 'linear-gradient(45deg, yellow, green, blue, red) 1',
    }
  }
}));

export default useNotificationPopUpStyles;
import React, { useState } from "react";
import { styled } from "@material-ui/core";
import Title from "../common/Title";
import SubTitle from "../common/SubTitle";
import AnnouncementDetailsModal from "./AnnouncementDetailsModal";

const ItemWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: 16,

  padding: 12,
  backgroundColor: "#FFFFFF",
  border: "1px solid #F4F6FB",
  borderRadius: 12,

  "& .imageWrapper": {
    height: 72,
    width: 84,
    "& .image": {
      height: "100%",
      width: "100%",
      borderRadius: 10,
      objectFit: "cover",
    },
  },

  "& .textWrapper": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    "& .title": {
      fontSize: 14,
      fontWeight: 600,
      lineHeight: "16px",
    },

    "& .subtitle": {
      width:'430px',
      whiteSpace:'nowrap',
      overflow:'hidden',
      textOverflow:'ellipsis',
    },
    "& .learnMore": {
      // fontFamily: "'Inter', sans-serif",
      fontWeight: 500,
      fontSize: 12,
      color: "#3E50F7",
      cursor:'pointer'
    },
  },
}));

const EachAnnouncement = ({ item }) => {
  const [announcementDetailsModalOpen, setAnnouncementDetailsModalOpen] = useState(false);
  return (
    <ItemWrapper>
      <div className="imageWrapper">
        <img src={item.imageUrl} alt="ancmnt" className="image" />
      </div>
      <div className="textWrapper">
        <Title text={item.title} className="title" style={{ fontSize:"16px" }} />
        <SubTitle text={item.description} className="subtitle" />

        <div onClick={() => setAnnouncementDetailsModalOpen(true)} className="learnMore">
          Learn more
        </div>
        {
          announcementDetailsModalOpen && (
            <AnnouncementDetailsModal 
            open={announcementDetailsModalOpen}
            onClose={() => setAnnouncementDetailsModalOpen(false)}
            item={item}
            />
          )
        }
      </div>
    </ItemWrapper>
  );
};

export default EachAnnouncement;

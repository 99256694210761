import { Button, Checkbox, Divider, makeStyles } from "@material-ui/core";
import React from "react";
import OnboardingFooter from "../OnboardingFooter";
import { useContext } from "../context";
import {ONBOARD_STATUS} from "../constants";
import insuranceDashboardService from "../../../reducers/Insurance-dashboard/insuranceDashboardService";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  contentHeadline: {
    display: "flex",
    alignItems: "center",
    gap: 16,
    marginTop: 14,
    marginBottom: 24,
  },
  title: {
    whiteSpace: "nowrap",
    fontSize: 18,
    fontWeight: 500,
    margin: 0,
  },
  dividerWrapper: {
    width: "100%",
  },
  divider: {
    // width: "96%",
  },
  list: {
    display: "flex",
    flexDirection: "column",
  },
  listItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",

    backgroundColor: "#fff",
    border: "1px solid #3e50f73d",
    padding: 16,
    borderRadius: 2,
  },

  leftWrapper: {
    display: "flex",
    alignItems: "center",
    gap: "16px",
  },

  textWrapper: {
    display: "flex",
    flexDirection: "column",

    "& .title": {
      fontSize: 16,
      fontWeight: 500,
      margin: 0,
    },
    "& .caption": {
      fontSize: 12,
      margin: 0,
      color: "#a1a1a1",
    },
    "& .description": {
      fontSize: 14,
      fontWeight: 400,
      margin: 0,
      color: "#646464",
    },
  },

  actionButton: {
    backgroundColor: "#3E50F7",
    minWidth: 156,
    "&:hover, &:focus": {
      backgroundColor: "#3E50F7",
    },
  },
}));

const BuildingBasicTabContent = () => {
  const classes = useStyles();
  const { state, contextAction } = useContext();
  const { optional } = state;
  const { updateOptional } = contextAction;

  const handleMarkStepComplete = async (key, buttonLink, status, index) => {
    window.open(buttonLink, "_blank");
  };

  const handleMarkAsDone = async (key, buttonLink, status, index) => {
    if (status !== ONBOARD_STATUS.COMPLETED){
      try {
        let resp = await insuranceDashboardService.checkStepStatus({ key: key });

        if (resp && resp.success && resp.data.success){
          updateOptional(ONBOARD_STATUS.COMPLETED, "status", index);
        }else if (resp && resp.success && !resp.data.success){
          window.showNotification("error", resp.data.message);
        }else {
          window.showNotification("error", resp.message || "Failed to mark as done");
        }
      }catch (err){
        window.showNotification("error","Failed to mark as done");
      }
    }
  };

  return (
    <div className={`${classes.root} tabsContent`}>
      <div className={classes.contentHeadline}>
        <h5 className={classes.title}>Building On The Basics</h5>
        <div className={classes.dividerWrapper}>
          <Divider className={classes.divider} />
        </div>
      </div>

      <div className={classes.list}>
        {
          optional.map((optionalItem, index) => (
            <div className={classes.listItem}>
              <div className={classes.leftWrapper}>
                <Checkbox
                  checked={optionalItem.status === ONBOARD_STATUS.COMPLETED}
                  onClick={async ()=>{ await handleMarkAsDone(optionalItem.key, optionalItem.buttonLink, optionalItem.status, index) }}
                  style={{ color: "#3E50F7" }}
                />

                <div className={classes.textWrapper}>
                  <h6 className="title">
                    {optionalItem.title}
                    <span className="caption"> {optionalItem.subText}</span>
                  </h6>
                  <p className="caption">{optionalItem.time}</p>
                  <p className="description">
                    {optionalItem.description}
                  </p>
                </div>
              </div>

              <div>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.actionButton}
                  disableElevation
                  onClick={() => handleMarkStepComplete(optionalItem.key, optionalItem.buttonLink, optionalItem.status, index)}
                >
                  {optionalItem.buttonText}
                </Button>
              </div>
            </div>
          ))
        }
      </div>

      <OnboardingFooter />
    </div>
  );
};

export default BuildingBasicTabContent;

import React from "react";
import {Grid} from "@material-ui/core";
import {TaskIcon} from "../common/Icons";
import Title from "../common/Title";
import SubTitle from "../common/SubTitle";
import {EachActivityWrapper} from "./index";

const formatTotalTask = (totalTask) => {
    try {
        if (totalTask === undefined || totalTask === null || isNaN(totalTask)) {
            throw new Error("Invalid input");
        }
        totalTask = Number(totalTask);

        if (totalTask >= 1000000) {
            return (totalTask / 1000000).toFixed(2) + 'M';
        } else if (totalTask >= 1000) {
            return (totalTask / 1000).toFixed(2) + 'k';
        } else {
            return totalTask;
        }
    } catch (error) {
        return "0";
    }
}


const Task = ({ activity }) => {
    return(
        <Grid container spacing={3}>
            <Grid item xs={12} lg={12}>
                <EachActivityWrapper bgColor={"#ECF4FF"}>
                    <div className="goalIcon">
                        <TaskIcon />
                    </div>
                    <div className="GoalText">
                        <Title text={formatTotalTask(activity?.totalTaskCompleted ?? 0)} />
                        <SubTitle text={"Total Tasks Completed"} className="subTitle" />
                    </div>
                </EachActivityWrapper>
            </Grid>
        </Grid>
    );
};

export default Task;
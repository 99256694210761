import React, { useEffect } from "react";
import { styled } from "@material-ui/core";
import Title from "../common/Title";
import EachAnnouncement from "./eachAnnouncement";
import { useSelector } from "react-redux";
import { selectInsuranceDashboardState } from "../../../reducers/Insurance-dashboard/insuranceDashboardSelector";
import AnnouncementsSkeleton from "../skeleton/AnnouncementsSkeleton";

const AnnouncementsWrapper = styled("div")(({ theme }) => ({
  padding: 24,
  backgroundColor: "#ffffff",
  border: "1px solid #E9EAEC",
  borderRadius: 12,
  "& .header": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 24,

    "& .actions": {
      display: "flex",
      alignItems: "center",
      gap: 8,
      "& .iconButton":{
        '&:focus': {
        backgroundColor:'transparent'
      },
      },

      "& .userWrap": {
        display: "flex",
        alignItems: "center",
        gap: 2,
        cursor: "pointer",
      },
    },
  },

  "& .announcementList": {
    display: "flex",
    flexDirection: "column",
    gap: 16,
    height:'210px',
    overflowY: "auto",
    paddingRight: "6px",
  
    "&::-webkit-scrollbar": {
      width: "6px",
      background: "#FFFFFF",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#627692",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#fff",
      borderRadius: "10px",
    },
  },
}));


const Announcements = () => {
  const { announcementList,loadingAnnouncementData } = useSelector(
    selectInsuranceDashboardState
  );


  return (
    <AnnouncementsWrapper>
      <div className="header">
        <Title text="Announcements" />

        <div className="actions">
        </div>
      </div>

{loadingAnnouncementData ? ( <AnnouncementsSkeleton /> ) : ( 
  announcementList && (() => {
    let hasValidData = false;

    const renderedItems = announcementList.map((item, index) => {
      if (item.title && item.description) {
        hasValidData = true;
        return (
          <EachAnnouncement
            key={index}
            item={item}
          />
        );
      }
      return null;
    });
    return hasValidData ? (
      <div className="announcementList">
        {renderedItems}
      </div>
    ) : (
      <div style={{ minHeight: '210px' }}>No Announcements</div>
    );
  })()
)}
    </AnnouncementsWrapper>
  );
};

export default Announcements;

import React from "react";
import { Button, styled } from "@material-ui/core";
import insuranceDashboardService from "../../reducers/Insurance-dashboard/insuranceDashboardService";
import Utils from "../../helpers/Utils";
import {useContext} from "./context";
import {ONBOARD_STATUS} from "./constants";

const FooterWrapper = styled("div")(({ theme }) => ({
  textAlign: "center",
  paddingBlock: "40px",

  "& .title": {
    marginBottom: 24,
  },

  "& .actionButton": {
    backgroundColor: "#3E50F7",
    "&:hover, &:focus": {
      backgroundColor: "#3E50F7",
    },
  },
}));

const OnboardingFooter = () => {
  const { state } = useContext();
  const { isOnboardCompleted } = state;
  const redirectToDashboard = async () => {
    try {
      if (isOnboardCompleted === ONBOARD_STATUS.COMPLETED){
        Utils.updateCookieData('userOnboard', false);
        window.location.href = '/';
      }else {
        const response = await insuranceDashboardService.updateOnboardComplete();
        if (response?.success) {
          if (Utils.getAccountData("userOnboard")){
            Utils.updateCookieData("userOnboard", false);
          }
          window.location.href = '/';
        }
      }
    } catch (error) {
      console.log(error);
    }
  };


  return (
    <FooterWrapper>
      <h4 className="title">
        Have you had enough, or are you thirsty <br /> for more knowledge?
      </h4>

      <Button
        variant="contained"
        color="primary"
        className={"actionButton"}
        disableElevation
        onClick={redirectToDashboard}
      >
        Go back to Dashboard
      </Button>
    </FooterWrapper>
  );
};

export default OnboardingFooter;

import { useMemo, useReducer, createContext, useContext as reactUseContext } from "react";
import PropTypes from "prop-types";
import { initialState, contextReducer } from "./reducer";
import contextActions from "./actions";

const Context = createContext();

const ContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(contextReducer, initialState);
    const value = useMemo(() => [state, dispatch], [state]);

    return <Context.Provider value={value}>{children}</Context.Provider>;
};

ContextProvider.propTypes = {
    children: PropTypes.any.isRequired,
};

const useContext = () => {
    const context = reactUseContext(Context);
    if (context === undefined) {
        throw new Error("useContext must be used within an ContextProvider");
    }
    const [state, dispatch] = context;
    const contextAction = useMemo(() => contextActions(dispatch), [dispatch]);

    return { state, contextAction };
};

export { ContextProvider, useContext };
import React from 'react';
import { Skeleton } from '@material-ui/lab';
const ActivitySkeleton = () => {
    
    return (
    
        <div style={{width:'100%',backgroundColor:'white',padding:'12px',display:'flex',justifyContent:'space-between',gap:'20px'}}>
            <Skeleton variant="rect" width={'100%'} height={'150px'} style={{borderRadius:'6px'}} />
            <Skeleton variant="rect" width={'100%'} height={'150px'} style={{borderRadius:'6px'}} />
        </div>
    
    );
};

export default ActivitySkeleton;

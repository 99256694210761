import { LinearProgress, styled } from "@material-ui/core";
import React from "react";
import Title from "../common/Title";
import SubTitle from "../common/SubTitle";
import {useSelector} from "react-redux";
import {selectInsuranceDashboardState} from "../../../reducers/Insurance-dashboard/insuranceDashboardSelector";
import Utils from "../../../helpers/Utils";

const ListWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: 8,
  marginTop: 8,

  height: "494px",
  overflowY: "auto",
  paddingRight: "6px",

  "&::-webkit-scrollbar": {
    width: "6px",
    background: "#FFFFFF",
    borderRadius: "10px",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#627692",
    borderRadius: "10px",
  },
  "&::-webkit-scrollbar-track": {
    background: "#fff",
    borderRadius: "10px",
  },

  "& .listItem": {
    backgroundColor: "#fff",
    padding: "8px 16px",
    border: "1px solid #DEE0E3",
    borderRadius: 12,
    boxShadow: "0px 1px 2px 0px #14151A0D",

    "& .title": {
      fontSize: 16,
      lineHeight: "24px",
      marginBottom: 4,
      textTransform:'capitalize'
    },
    "& .subTitle": {
      color: "#141928",
    },

    "&:not(:first-child)": {
      marginTop: 8,
    },

    "& .textWrapper": {
      //   display: "flex",
      //   justifyContent: "space-between",
      marginBottom: 8,
    },
  },
}));

const ProgressStyle = styled(LinearProgress)(({ theme, barColor }) => ({
  height: 8,
  borderRadius: 5,
  "& .MuiLinearProgress-barColorPrimary": {
    backgroundColor: barColor || "#346fef",
  },
}));

const DealStageList = () => {
  const { stageInformation } = useSelector(selectInsuranceDashboardState);

  return (
    <ListWrapper className="listWrapper">
      {stageInformation.map((item, index) => (
        <div className="listItem" key={index}>
          <div className="textWrapper">
            <Title text={item.stage} className="title" />
            <SubTitle text={`Total Deals: ${item.dealCount}     Total Premium: ${Utils.formatCurrency(item.dealValue, "$")}`} className="subTitle" />
          </div>

          <ProgressStyle variant="determinate" barColor={item.colorCode} value={item.percentage} />
        </div>
      ))}
    </ListWrapper>
  );
};

export default DealStageList;

import * as actionTypes from "./types";
import {ONBOARD_STATUS} from "../constants";

export const initialState = {
  recommended: [],
  optional: [],
  isOnboardCompleted: ONBOARD_STATUS.NOT_COMPLETE,
};

export const contextReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.SET_RECOMMENDED:
      return {
        ...state,
        recommended: action.payload,
      };
    case actionTypes.UPDATE_RECOMMENDED:
      let tempRecommended = [...state.recommended];
      tempRecommended[action.payload.index][action.payload.key] = action.payload.value;

      return {
        ...state,
        recommended: tempRecommended
      };
    case actionTypes.SET_OPTIONAL:
      return {
        ...state,
        optional: action.payload,
      };
    case actionTypes.UPDATE_OPTIONAL:
      let tempOptional = [...state.optional];
      tempOptional[action.payload.index][action.payload.key] = action.payload.value;

      return {
        ...state,
        optional: tempOptional
      };
    case actionTypes.SET_ONBOARD_STATUS:
      return {
        ...state,
        isOnboardCompleted: action.payload,
      };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

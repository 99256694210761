import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    APPOINTMENT_SCHEDULE_PAST,
    APPOINTMENT_SCHEDULE_TODAY, APPOINTMENT_SCHEDULE_UPCOMING,
    FILTER_TYPE_TEAM,
    FILTER_TYPE_USER
} from "../../../constants/CoreConstants";
import { selectInsuranceDashboardState } from "../../../reducers/Insurance-dashboard/insuranceDashboardSelector";
import {
    fetchAppointmentCountStart,
} from "../../../reducers/Insurance-dashboard/insuranceDashboardSlice";
import { ArrowRightIcon } from "../common/Icons";
import Title from "../common/Title";
import UserChip from "../common/UserChip";
import CardSectionTab from "./CardSectionTab";
import { CardWrapper, GoButton } from "./index";

const TaskCard = ({ goToLink }) => {
    const dispatch = useDispatch();
    const { totalAppointmentCountFilterType, totalAppointmentCount, appointmentSchedule } = useSelector(selectInsuranceDashboardState);

    return (
        <CardWrapper className="TaskCard">
            <div>
                <div className="header">
                    <Title text={"Tasks & Appointments"} className="title" />

                    <div className="actions">
                        <UserChip filterUser={totalAppointmentCountFilterType} onClickAction={() => dispatch(fetchAppointmentCountStart({ schedule: appointmentSchedule, userFilter: totalAppointmentCountFilterType === FILTER_TYPE_TEAM ? FILTER_TYPE_USER : FILTER_TYPE_TEAM }))} />
                    </div>
                </div>
            </div>

            <div className="body">
                <div>
                    {/* {
                           loadingAppointmentCount ?  <Skeleton variant="text" width={100} height={32} style={{marginBottom:'4px'}}/> : */}
                    <Title text={totalAppointmentCount} className="value" style={{ marginBottom: 4 }} />
                    {/* } */}
                    <CardSectionTab
                        list={[
                            { id: APPOINTMENT_SCHEDULE_PAST, title: "Past Due" },
                            { id: APPOINTMENT_SCHEDULE_TODAY, title: "Today" },
                            { id: APPOINTMENT_SCHEDULE_UPCOMING, title: "Upcoming" },
                        ]}
                        activeTab={appointmentSchedule}
                        onActiveTab={(id) => {
                            dispatch(fetchAppointmentCountStart({
                                schedule: id,
                                userFilter: totalAppointmentCountFilterType
                            }))
                        }}
                        className="taskTab"
                    />
                </div>
                <GoButton
                    variant="outlined"
                    color="primary"
                    endIcon={<ArrowRightIcon className="goIcon" />}
                    fullWidth
                    onClick={() => goToLink('/activity-calendar', null, '?tab=list')}
                >
                    Go
                </GoButton>
            </div>

        </CardWrapper>
    );
};

export default TaskCard;

import React from "react";
import { LinearProgress, styled } from "@material-ui/core";
import Utils from "../../../helpers/Utils";
import SubTitle from "../common/SubTitle";
import Title from "../common/Title";

const ListWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: 8,
  marginTop: 8,

  height: "494px",
  overflowY: "auto",
  paddingRight: "6px",

  "&::-webkit-scrollbar": {
    width: "6px",
    background: "#FFFFFF",
    borderRadius: "10px",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#627692",
    borderRadius: "10px",
  },
  "&::-webkit-scrollbar-track": {
    background: "#fff",
    borderRadius: "10px",
  },

  "& .listItem": {
    backgroundColor: "#fff",
    padding: "8px 16px",
    border: "1px solid #DEE0E3",
    borderRadius: 12,
    boxShadow: "0px 1px 2px 0px #14151A0D",

    "& .title": {
      fontSize: 16,
      lineHeight: "24px",
      marginBottom: 4,
      textTransform:'capitalize'
    },
    "& .subTitle": {
      color: "#141928",
    },

    "&:not(:first-child)": {
      marginTop: 8,
    },

    "& .textWrapper": {
      //   display: "flex",
      //   justifyContent: "space-between",
      marginBottom: 8,
    },
  },
}));

const ProgressStyle = styled(LinearProgress)(({ theme, barColor }) => ({
  height: 8,
  borderRadius: 5,
  "& .MuiLinearProgress-barColorPrimary": {
    backgroundColor: barColor || "#346fef",
  },
}));

const STAGE_DUMMY_DATA = [
  {
    dealValue: 936,
    dealCount: 7,
    name: "New Leads",
    colorCode: "#3e50f7",
    value: 20
  },
  {
    dealValue: 1234,
    dealCount: 10,
    name: "Contacted",
    colorCode: "#f39c12",
    value: 20
  },
  {
    dealValue: 789,
    dealCount: 5,
    name: "Negotiation",
    colorCode: "#e74c3c",
    value: 20
  },
  {
    dealValue: 456,
    dealCount: 3,
    name: "Closed",
    colorCode: "#2ecc71",
    value: 20
  },
  {
    dealValue: 1123,
    dealCount: 9,
    name: "Qualified",
    colorCode: "#8e44ad",
    value: 20
  }
]

const BlurredDealStageList = () => {

  return (
    <ListWrapper className="listWrapper">
      {STAGE_DUMMY_DATA.map((item, index) => (
        <div className="listItem" key={index}>
          <div className="textWrapper">
            <Title text={item.stage} className="title" />
            <SubTitle text={`Total Deals: ${item.dealCount}     Total Premium: ${Utils.formatCurrency(item.dealValue, "$")}`} className="subTitle" />
          </div>

          <ProgressStyle variant="determinate" barColor={item.colorCode} value={item.percentage} />
        </div>
      ))}
    </ListWrapper>
  );
};

export default BlurredDealStageList;

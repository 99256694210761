export const fetchSidebarReducer = {
    fetchUserActivity: (state, action) => {
        state.sidebar.isLoading = true;
    },
    fetchUserActivitySuccess: (state, action) => {
        state.sidebar.isLoading = false;

        if(action.payload.length > 0) {
            state.sidebar.activityList.push(...action.payload);
            state.sidebar.hasMore = true;
        }else{
            state.sidebar.hasMore = false;
        }
    },
    fetchUserActivityFail: (state, action) => {
        state.sidebar.isLoading = false;
        state.sidebar.activityList = [];
        state.error = action.payload;
    },

    setSidebarScheduleType: (state, action) => {
        state.sidebar.isLoading = true;
        state.sidebar.activityList = [];
        state.sidebar.scheduleType = action.payload;
    },

    setSidebarPage: (state, action) => {
        state.sidebar.page = action.payload;
    },
    resetSidebarPage: (state) => {
        state.sidebar.page = 1;
        state.sidebar.activityList = [];
        state.sidebar.inboxList = [];
        state.sidebar.notificationList = [];
    },

    fetchUserInbox: (state, action) => {
        state.sidebar.isLoading = true;
    },
    fetchUserInboxSuccess: (state, action) => {
        state.sidebar.isLoading = false;

        if(action.payload.length > 0) {
            state.sidebar.inboxList.push(...action.payload);
            state.sidebar.hasMore = true;
        }else{
            state.sidebar.hasMore = false;
        }
    },
    fetchUserInboxFail: (state, action) => {
        state.sidebar.isLoading = false;
        state.sidebar.inboxList = [];
        state.error = action.payload;
    },
    fetchSidebarNotification: (state, action) => {
        state.sidebar.isLoading = true;
    },
    fetchSidebarNotificationSuccess: (state, action) => {
        state.sidebar.isLoading = false;

        if(action.payload.length > 0) {
            state.sidebar.notificationList.push(...action.payload);
            state.sidebar.hasMore = true;
        }else{
            state.sidebar.hasMore = false;
        }
    },
    fetchSidebarNotificationFail: (state, action) => {
        state.sidebar.isLoading = false;
        state.sidebar.notificationList = [];
        state.error = action.payload;
    },
}
import { styled } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { checkCookie } from "../../helpers/Cookie";
import insuranceDashboardService from "../../reducers/Insurance-dashboard/insuranceDashboardService";
import BuildingBasic from "./buildingBasic/BuildingBasic";
import { useContext } from "./context";
import withContext from "./context/withContext";
import GetStarted from "./getStarted/GetStarted";
import OnboardingSkeleton from "./Skeleton/OnboardingSkeleton";
import Utils from "../../helpers/Utils";
import {ONBOARD_STATUS} from "./constants";
import withAuthCheck from "./CheckAuth";

const OnboardWrapper = styled("div")(({ theme, bgColor = "#e3e8fb80" }) => ({
  background: bgColor,
  paddingRight: "240px"
}));

const InsuranceOnboard = () => {
  const { contextAction } = useContext();
  const { setRecommended, setOptional, setIsOnboardCompleted } = contextAction;
  const [loading, setLoading] = useState(true);

  useEffect(()=>{
        document.title = 'Onboarding';

        if (checkCookie(process.env.REACT_APP_ACCESS_TOKEN)){
            handleGetOnboardData();
        }else {
            window.open("/user/login", "_self");
        }
    },[]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleGetOnboardData = async () => {
      setLoading(true);
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      try{
          const response = await insuranceDashboardService.getOnboardData({timezone});

          if (response?.success){
              setRecommended(response.data.stepOne);
              setOptional(response.data.stepTwo);
              setIsOnboardCompleted(response.data.isOnboardCompleted);

              const isOnboardCompleted = response.data.isOnboardCompleted === ONBOARD_STATUS.COMPLETED;

              if (Utils.getAccountData("userOnboard") !== isOnboardCompleted) {
                  Utils.updateCookieData("userOnboard", !isOnboardCompleted);
              }
          }
      }catch (err){
          console.log(err);
      } finally {
          setLoading(false);
      }
  };

  return (
    <OnboardWrapper bgColor={ loading ? "#fff" : "#e3e8fb80"}>
    { loading ?
        <OnboardingSkeleton/> :
     ( <>
      <GetStarted />
      <BuildingBasic />
      </>
    )}
    </OnboardWrapper>
  );
};

export default withAuthCheck(withContext(InsuranceOnboard));

import axios from "axios";
import { getCookie } from "../../helpers/Cookie";

const accessToken = process.env.REACT_APP_ACCESS_TOKEN ? getCookie(process.env.REACT_APP_ACCESS_TOKEN) : '';

// ## handle http request response
const responseBody = (response) => {
    return response.data;
};

// ## handle http request error
const errorResponseBody = (error) => {
    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        return error.response.data;
    }

    return error.request;
};

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    timeout: 10000,
    headers: {
        Authorization: `Bearer ${accessToken}`,
        "Access-Control-Allow-Headers": "*",
    },
});

const httpRequest = {
    get: (url = "", params = {}) =>
        axiosInstance
            .get(url, {
                params: params,
            })
            .then(responseBody)
            .catch(errorResponseBody),

    post: (url = "", body = {}) =>
        axiosInstance
            .post(url, body)
            .then(responseBody)
            .catch(errorResponseBody),

    put: (url = "", body = {}) =>
        axiosInstance
            .put(url, body)
            .then(responseBody)
            .catch(errorResponseBody),

    delete: (url = "", params = {}, body = {}) =>
        axiosInstance
            .delete(url, {
                data: body,
                params: params,
            })
            .then(responseBody)
            .catch(errorResponseBody),
    multipart: (url = "", formData) =>
        axiosInstance
            .post(url, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then(responseBody)
            .catch(errorResponseBody),
};

export default httpRequest;

export const CloseIcon = ({width="19", height="19", fill="#546376"}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.75 5.3075L13.6925 4.25L9.5 8.4425L5.3075 4.25L4.25 5.3075L8.4425 9.5L4.25 13.6925L5.3075 14.75L9.5 10.5575L13.6925 14.75L14.75 13.6925L10.5575 9.5L14.75 5.3075Z" fill={fill}/>
        </svg>
    );
}
export const ThumbsUpIcon = ({width="24", height="24", fill="#000000"}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height={`${height}px`} viewBox="0 0 24 24" width={`${width}px`} fill={fill}>
            <path d="M0 0h24v24H0V0zm0 0h24v24H0V0z" fill="none"/>
            <path d="M9 21h9c.83 0 1.54-.5 1.84-1.22l3.02-7.05c.09-.23.14-.47.14-.73v-2c0-1.1-.9-2-2-2h-6.31l.95-4.57.03-.32c0-.41-.17-.79-.44-1.06L14.17 1 7.58 7.59C7.22 7.95 7 8.45 7 9v10c0 1.1.9 2 2 2zM9 9l4.34-4.34L12 10h9v2l-3 7H9V9zM1 9h4v12H1z"/>
        </svg>
    );
}
export const CheckCircleIcon = ({width="24", height="24", fill="#000000"}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.55 19.91a1.72 1.72 0 0 1-1.19-.49l-6.2-6.21a.74.74 0 0 1 0-1.06l2.41-2.42a1.73 1.73 0 0 1 2.43 0l2.78 2.79a.18.18 0 0 0 .25 0l7.35-7.35a1.72 1.72 0 0 1 2.38 0l1.45 1.45a1.68 1.68 0 0 1 0 2.37L10.74 19.42a1.72 1.72 0 0 1-1.19.49Zm-5.8-7.23 5.67 5.67a.18.18 0 0 0 .25 0L20.1 7.93a.18.18 0 0 0 0-.25l-1.45-1.45a.19.19 0 0 0-.26 0L11 13.58a1.72 1.72 0 0 1-2.37 0l-2.74-2.79a.16.16 0 0 0-.26 0l-1.88 1.89Z" fill={fill}/>
        </svg>
    );
}
export const ActivityIcon = ({width="24", height="24", fill="#fff"}) => {
    return (
      <svg
        fill={fill}
        height={height}
        viewBox="0 0 24 24"
        width={width}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-rule="evenodd" fill="rgb(0,0,0)" fill-rule="evenodd">
          <path d="m3.09467 3.09467c1.33447-1.33447 3.33136-1.84467 5.90533-1.84467h6c2.574 0 4.5709.5102 5.9053 1.84467 1.3345 1.33447 1.8447 3.33136 1.8447 5.90533v6c0 2.574-.5102 4.5709-1.8447 5.9053-1.3344 1.3345-3.3313 1.8447-5.9053 1.8447h-6c-2.57397 0-4.57086-.5102-5.90533-1.8447-1.33447-1.3344-1.84467-3.3313-1.84467-5.9053v-6c0-2.57397.5102-4.57086 1.84467-5.90533zm1.06066 1.06066c-.91553.91553-1.40533 2.41864-1.40533 4.84467v6c0 2.426.4898 3.9291 1.40533 4.8447.91553.9155 2.41864 1.4053 4.84467 1.4053h6c2.426 0 3.9291-.4898 4.8447-1.4053.9155-.9156 1.4053-2.4187 1.4053-4.8447v-6c0-2.42603-.4898-3.92914-1.4053-4.84467-.9156-.91553-2.4187-1.40533-4.8447-1.40533h-6c-2.42603 0-3.92914.4898-4.84467 1.40533z" />
          <path d="m17.1296 8.91704c.3274.25377.387.72488.1333 1.05225l-2.3144 2.98571c-.5949.7523-1.6918.8895-2.457.2983l-.0052-.0041-1.8266-1.4373c-.1128-.0857-.2702-.0644-.3559.0462 0 .0001.0001-.0002 0 0l-2.3795 3.0894c-.25276.3281-.72368.3892-1.05184.1365-.32816-.2528-.38928-.7237-.13653-1.0519l2.38072-3.0909c.59391-.7686 1.69515-.9075 2.46205-.3149l.0052.0041 1.8266 1.4373c.114.0866.2747.0643.3598-.0412l2.3071-2.97619c.2537-.32737.7248-.38704 1.0522-.13327z" />
        </g>
      </svg>
    );
}
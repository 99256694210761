import { Button, Checkbox, Divider, makeStyles } from "@material-ui/core";
import React from "react";
import {useContext} from "../context";
import {JOIN_FACEBOOK_GROUP, ONBOARD_STATUS} from "../constants";
import insuranceDashboardService from "../../../reducers/Insurance-dashboard/insuranceDashboardService";
import OnboardingHeader from "../OnboardingHeader";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  contentHeadline: {
    display: "flex",
    alignItems: "center",
    gap: 16,
    marginTop: 14,
    marginBottom: 24,
  },
  title: {
    whiteSpace: "nowrap",
    fontSize: 18,
    fontWeight: 500,
    margin: 0,
  },
  dividerWrapper: {
    width: "100%",
  },
  divider: {
    // width: "96%",
  },
  list: {
    display: "flex",
    flexDirection: "column",
  },
  listItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",

    backgroundColor: "#fff",
    border: "1px solid #3e50f73d",
    padding: 16,
    borderRadius: 2,
  },

  leftWrapper: {
    display: "flex",
    alignItems: "center",
    gap: "16px",
  },

  textWrapper: {
    display: "flex",
    flexDirection: "column",

    "& .title": {
      fontSize: 16,
      fontWeight: 600,
      margin: 0,
    },
    "& .caption": {
      fontSize: 12,
      margin: 0,
      color: "#a1a1a1",
    },
    "& .description": {
      fontSize: 14,
      fontWeight: 400,
      margin: 0,
      color: "#646464",
    },
  },

  actionButton: {
    backgroundColor: "#3E50F7",
    minWidth: 156,
    "&:hover, &:focus": {
      backgroundColor: "#3E50F7",
    },
  },
}));

const GetStartedTabContent = () => {
  const classes = useStyles();
  const { state, contextAction } = useContext();
  const { recommended } = state;
  const { updateRecommended } = contextAction;

  const handleMarkStepComplete = async (key, buttonLink, status, index) => {
    if (status !== ONBOARD_STATUS.COMPLETED && key === JOIN_FACEBOOK_GROUP){
      updateRecommended(ONBOARD_STATUS.COMPLETED, "status", index);
      await insuranceDashboardService.markStepComplete({ key: JOIN_FACEBOOK_GROUP });
    }
    window.open(buttonLink, "_blank");
  };

  const handleMarkAsDone = async (key, buttonLink, status, index) => {
    if (status !== ONBOARD_STATUS.COMPLETED){
      try {
        if (status !== ONBOARD_STATUS.COMPLETED && key === JOIN_FACEBOOK_GROUP){
          updateRecommended(ONBOARD_STATUS.COMPLETED, "status", index);
          await insuranceDashboardService.markStepComplete({ key: JOIN_FACEBOOK_GROUP });
        }else {
          let resp = await insuranceDashboardService.checkStepStatus({ key: key });

          if (resp && resp.success && resp.data.success){
            updateRecommended(ONBOARD_STATUS.COMPLETED, "status", index);
          }else if (resp && resp.success && !resp.data.success){
            window.showNotification("error", resp.data.message);
          }else {
            window.showNotification("error", resp.message || "Failed to mark as done");
          }
        }
      }catch (err){
        window.showNotification("error","Failed to mark as done");
      }
    }
  };

  return (
    <div className={`${classes.root} tabsContent`}>
      <OnboardingHeader />
      <div className={classes.contentHeadline}>
        <h5 className={classes.title}>
          Get Started With The Basics (recommended)
        </h5>
        <div className={classes.dividerWrapper}>
          <Divider className={classes.divider} />
        </div>
      </div>

      <div className={classes.list}>
        {
          recommended.map((recommendedItem, index)=> (
              <div className={classes.listItem}>
                <div className={classes.leftWrapper}>
                  <Checkbox
                      checked={recommendedItem.status === ONBOARD_STATUS.COMPLETED}
                      color="primary"
                      style={{color: "#3E50F7"}}
                      onClick={async ()=>{ await handleMarkAsDone(recommendedItem.key, recommendedItem.buttonLink, recommendedItem.status, index) }}
                      inputProps={{"aria-label": "primary checkbox"}}
                  />

                  <div className={classes.textWrapper}>
                    <h6 className="title">{recommendedItem.title}</h6>
                    <p className="caption">{recommendedItem.time}</p>
                    <p className="description">
                      {recommendedItem.description}
                    </p>
                  </div>
                </div>

                <div>
                  <Button
                      variant="contained"
                      color="primary"
                      className={classes.actionButton}
                      disableElevation
                      onClick={async ()=>{ await handleMarkStepComplete(recommendedItem.key, recommendedItem.buttonLink, recommendedItem.status, index) }}
                  >
                    {recommendedItem.buttonText}
                  </Button>
                </div>
              </div>
          ))
        }
      </div>
    </div>
  );
};

export default GetStartedTabContent;

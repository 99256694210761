import {
  Box,
  Button,
  Dialog,
  Divider,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React from "react";

const useStyles = makeStyles({
  titleHeader: {
    fontSize: "20px",
    color: "white",
  },
  closeIconBox: {
    width: "25px",
    height: "25px",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#FF264A",
    cursor: "pointer",
  },
  CloseButton: {
    fontWeight: 600,
    background: "#346fef",
    color: "white",
    "&:focus,&:hover": {
      background: "#346fef",
    },
  },
  cardWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding:'16px',
  },
  imageWrapper: {
    width: "100%",
  },
  image: {
    height: "100%",
    width: "100%",
    borderRadius: 10,
    objectFit: "cover",
  },
  textWrapper: {
    width: "100%",
    marginTop: "20px",
  },
  title: {
    fontSize: 24,
    fontWeight: 600,
    lineHeight: "32px",
    color: "#14151A",
    margin: 0,
    paddingBottom: "20px",
  },
  descriptionText: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: "24px",
    color: "#7E869A",
    margin: 0,
  },
  scrollable:{
    height:'70vh',
    overflowY: "auto",
    paddingRight: "6px",
  
    "&::-webkit-scrollbar": {
      width: "6px",
      background: "#FFFFFF",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#627692",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#fff",
      borderRadius: "10px",
    },
  }
});

const AnnouncementDetailsModal = ({ open, onClose, item }) => {
  const classes = useStyles();

  return (
    <Dialog
      PaperProps={{
        style: {
          minWidth: "650px",
        },
      }}
      open={open}
      onClose={onClose}
    >
      <Box
        minWidth={"650px"}
        minHeight={300}
        sx={{ "& .MuiTypography-root": { height: "unset !important" } }}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          padding={2}
          style={{ backgroundColor: "#346FEF", marginBottom: "20px" }}
        >
          <Typography
            variant="body1"
            style={{ marginBottom: 4 }}
            className={classes.titleHeader}
          >
            Announcement Details
          </Typography>
          <Divider
            style={{ height: "1px", width: "55%", backgroundColor: "white" }}
          />

          <Box onClick={onClose} className={classes.closeIconBox}>
            <Close style={{ color: "white" }} />
          </Box>
        </Box>

        <div className={classes.cardWrapper} >
          <div className={classes.scrollable}>
          <div className={classes.imageWrapper}>
            <img src={item.imageUrl} alt="announcement" className={classes.image} />
          </div>
          <div className={classes.textWrapper}>
            <h6 className={classes.title}>{item.title}</h6>
            <p className={classes.descriptionText}>{item.description}</p>
          </div>
          </div>
        </div>

        <Box padding={2} display={"flex"} justifyContent={"end"}>
          <Box display={"flex"} gridGap={8} sx={{ fontWeight: 600 }}>
            <Button
              onClick={onClose}
              className={classes.CloseButton}
              variant="contained"
            >
              Close
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default AnnouncementDetailsModal;

import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { HistoryProvider } from "../../../App";
import DailyGoal from "./dailyGoal";
import MonthlyGoal from "./monthlyGoal";

const useStyles = makeStyles((theme) => ({
  gridWrapper: {
    position: "relative",

    "& .blurred": {
      filter: "blur(7px)",
      "-webkit-filter": "blur(7px)",
    },

    "& .action": {
      position: "absolute",
      top: "190px",
      left: "25%",
      transform: "translate(-50%, -50%)",
      fontSize: "20px",
      fontWeight: "bold",
    },

    "& .text": {
      fontSize: 24,
      textAlign: "left",
      fontWeight: 600,
      lineHeight: "32px",
      color: "rgb(0, 0, 0)",
      margin: 0,

      "& .actionButton": {
        marginTop: "15px",
        backgroundColor: "#3e50fe",
        "&:hover, &:focus": {
          backgroundColor: "#3e50fe",
        },
      },
    },
  },
}));

const GoalNotSelected = () => {
  const classes = useStyles();
  const history = useContext(HistoryProvider);

  return (
    <Grid item xs={12} className={classes.gridWrapper}>
      <Grid container spacing={3} className="blurred">
        <Grid item xs={12} md={12} lg={8}>
          <DailyGoal />
        </Grid>
        <Grid item xs={12} md={12} lg={4}>
          <MonthlyGoal />
        </Grid>
      </Grid>

      <div className="action">
        <h6 className="text">
          Create a sales goal to activate <br /> your dashboard! <br />
          <Link
            to={`/user/sales-reports`}
            onClick={(e) => {
              e.preventDefault();
              history.push(`/user/sales-reports`);
            }}
          >
            <Button
              variant="contained"
              color="primary"
              className="actionButton"
              disableElevation
            >
              Get Started
            </Button>
          </Link>
        </h6>
      </div>
    </Grid>
  );
};

export default GoalNotSelected;

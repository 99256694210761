import React from "react";
import { styled } from "@material-ui/core";
import { useSelector } from "react-redux";
import Utils from "../../../helpers/Utils";
import { selectInsuranceDashboardState } from "../../../reducers/Insurance-dashboard/insuranceDashboardSelector";
import { TrophyIcon } from "../common/Icons";
import CustomChart from "./CustomChart";
import GoalItem from "./GoalItem";
import { GOAL_TYPE_SALES } from "../../../constants/CoreConstants";

const GoalChartWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "100%",

  "& .userSelect": {
    width: 330,
    marginLeft: "auto !important",
    marginRight: "14px !important",
  },

  "& .needleChartWrapper": {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 8,
  },
}));

const GoalChart = () => {
  const {
    goalForToday,
    achievedToday,
    stillNeededForToday,
    goalStatFilter,
    selectedGoal
  } = useSelector(selectInsuranceDashboardState);

  const formatCurrency = (value) => {
    if (typeof value !== "number" || isNaN(value) || selectedGoal === null)
      return "N/A";

    if (goalStatFilter === GOAL_TYPE_SALES) {
      return "$" + Utils.abbreviateNumber(value) || "0.00";
    }
    return Utils.formatCurrency(Utils.toZero(value));
  };

  return (
    <GoalChartWrapper className="goalChart">
      <div className="needleChartWrapper">
        <CustomChart
          maxValue={goalForToday}
          currentValue={achievedToday}
          remaining={stillNeededForToday}
        />

        {/* <ProgressCircle
            data={{
                goalForToday: goalForToday,
                achievedToday: achievedToday,
                stillNeededForToday: stillNeededForToday,
            }}
        /> */}
      </div>

      <GoalItem
        text="ACHIEVED TODAY"
        value={formatCurrency(achievedToday)}
        icon={<TrophyIcon />}
        bgColor={"#FFFAF2"}
      />
    </GoalChartWrapper>
  );
};

export default GoalChart;

export const fetchUserSalesGoalReducers = {
    fetchUserSalesGoalStart: (state, action) => {
        state.loadingUserSalesGoalData = true;

        state.goalStatFilter = action.payload.goalStatFilter;
        state.userSaleGoalId = action.payload.userSaleGoalId;
    },
    fetchUserSalesGoalSuccess: (state, action) => {
        state.loadingUserSalesGoalData = false;
        state.userSaleGoalId = action.payload.userSaleGoalId ?? "";
        state.startedTodayAt = action.payload.startedTodayAt ?? 0;
        state.shouldBeAt = action.payload.shouldBeAt ?? 0;
        state.goalForToday = action.payload.goalForToday ?? 0;
        state.achievedToday = action.payload.achievedToday ?? 0;
        state.stillNeededForToday = action.payload.stillNeededForToday ?? 0;
        state.currentFrequencyTotalSale = action.payload.currentFrequencyTotalSale ?? 0;
        state.currentGoalPercentage = action.payload.currentGoalPercentage ?? 0;
        state.previousGoalTotalSale = action.payload.previousGoalTotalSale ?? 0;
        state.currentFrequencyPredictedSale = action.payload.currentFrequencyPredictedSale ?? 0;
        state.trendingForText = action.payload.trendingForText ?? '';
        state.currentVsLastSalesGrowth = action.payload.currentVsLastSalesGrowth ?? 0;
        state.currentMonth = action.payload.currentMonth ?? 0;
        state.currentFrequencyStartDate = action.payload.currentFrequencyStartDate ?? 0;
        state.currentFrequencyEndDate = action.payload.currentFrequencyEndDate ?? 0;
        state.currentYear = action.payload.currentYear ?? 0;
        state.frequency = action.payload.frequency;
    },
    fetchUserSalesGoalFail: (state, action) => {
        state.loadingUserSalesGoalData = false;
        state.error = action.payload;
    },
}
export const fetchAnnouncementReducer = {
    fetchAnnouncementStart: (state, action) => {
        state.loadingAnnouncementData = true;
    },
    fetchAnnouncementSuccess: (state, action) => {
        state.loadingAnnouncementData = false;
        state.announcementList = action.payload;
    },
    fetchAnnouncementFail: (state, action) => {
        state.loadingAnnouncementData = false;
        state.announcementList = [];
        state.error = action.payload;
    },
}
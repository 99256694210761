import { Chip } from "@material-ui/core";
import { Group, Person } from "@material-ui/icons";

const UserChip = ({ filterUser, onClickAction }) => {
    return (
        <Chip
            label={filterUser ? "Team" : "Me"}
            size="small"
            variant="outlined"
            icon={filterUser ? <Group fontSize="small" /> : <Person fontSize="small" />}
            clickable
            onClick={onClickAction}
        />
    );
}

export default UserChip;
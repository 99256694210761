import React, { Fragment, useEffect, useState } from "react";
import { Modal, Paper, } from "@material-ui/core";
import { connectNylas, inactiveUserConnectedEmail } from "./ApiService";
import Loading from "./Loading";
import useNotificationPopUpStyle from "./useNotificationPopUpStyle";
import Icons from "../dashboard/BrowserAlert/Icons";
import { emailProvider } from "../../constants/CoreConstants";

const NylasV3MigrationModal = ({ connectedFailedEmail, userData, open, onClose }) => {
    const classes = useNotificationPopUpStyle();
    const [email, setEmail] = useState("");
    const [defaultEmail, seDefaultEmail] = useState("");
    const [loaderText, setLoaderText] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [isDontShowAgain, setIsDontShowAgain] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        getNylasEmail();
    }, [connectedFailedEmail, userData]); // eslint-disable-line react-hooks/exhaustive-deps

    const failedReason = () => {
        if (userData?.email_provider === emailProvider.NYLAS_V1) {
            return "*** This action is necessary as part of our system upgrade process. ***";
        } else if (userData?.email_provider === emailProvider.NYLAS_V3 && connectedFailedEmail) {
            return `*** ${connectedFailedEmail["failReason"]} ***`;
        }
        return "";
    };

    const getNylasEmail = () => {
        if (userData?.email_provider === emailProvider.NYLAS_V1) {
            try {
                if (!isLoading && userData) {
                    let nylasEmailProviderInfo = JSON.parse(userData.nylas_email_provider_info);
                    setEmail(nylasEmailProviderInfo?.email);
                    seDefaultEmail(nylasEmailProviderInfo?.email);
                    setIsDisabled(false);
                }
            } catch (err) {
                console.log(err);
            }
        } else if (userData?.email_provider === emailProvider.NYLAS_V3 && connectedFailedEmail) {
            setEmail(connectedFailedEmail["email"]);
        }
    };

    const initConnectionProcess = async () => {
        try {
            const resp = await connectNylas({ email: email });
            if (resp && resp?.data?.success && resp?.data?.data?.redirect) {
                window.showNotification("success", "Please wait while redirecting...");
                setIsLoading(true);
                setLoaderText('Please wait while redirecting...');
                setTimeout(() => {
                    window.open(resp?.data?.data?.redirect, "_self");
                }, 2000);
            } else {
                setLoaderText(null);
                setIsLoading(false);
                window.showNotification('error', resp?.data?.message || 'Unable to connect. Please try again later!', 3000);
            }
        } catch (error) {
            setLoaderText(null);
            setIsLoading(false);
            window.showNotification("error", "Unable to connect. Please try again later!");
        } finally {

        }
    };

    const handleInactiveUserConnectedEmail = async () => {
        try {
            await inactiveUserConnectedEmail({ userConnectedEmailUid: connectedFailedEmail["uid"] });
        } catch (err) {
            console.log(err);
        } finally {
            onClose();
        }
    };

    const handleClose = async () => {
        if (connectedFailedEmail && isDontShowAgain) {
            await handleInactiveUserConnectedEmail();
        } else {
            onClose();
        }
    };

    const handleChangeInput = (event) => {
        setEmail(event.target.value);
        if (event.target.value !== defaultEmail) {
            setErrorMessage(`${defaultEmail} is the default email address. Changing it will disconnect your account from this email.`);
        } else {
            setErrorMessage("");
        }
    };

    return (
        <Modal
            open={open}
            onClose={() => { }}
            aria-labelledby="multi-stepper-modal-title"
            aria-describedby="multi-stepper-modal-description"
            className={`${classes.modalWrapper} global-xs-modal add_list_modal`}
            disableEnforceFocus
        >
            <Paper className={classes.modalInner}>
                {
                    isLoading ?
                        <div className={classes.modalBody}>
                            <div className={classes.bodyContainer}>
                                <Loading loaderText={loaderText} />
                            </div>
                        </div> :
                        <Fragment>
                            <div onClick={handleClose} className={classes.closeCircleIcon}>
                                {Icons.closeCircle}
                            </div>
                            <div className={classes.modalBody}>
                                <div className={classes.bodyContainer}>
                                    <div className={classes.bodyTextContainer}>
                                        <div className={`${classes.headerBg} ${classes.runningBorder}`}>
                                            <h1 className={classes.headerTitle}>
                                                Your email is disconnected.
                                            </h1>
                                            <h1 className={classes.headerSubTitle}>
                                                Please reconnect it to ensure smooth service.
                                            </h1>
                                        </div>
                                        <span className={classes.messageTitle}>
                                            <p className={classes.failedReason}>{failedReason()}</p>
                                            <p>We have made some upgrades to our email platform that require action on your part. Please   <span
                                                className={classes.textStrong}>reconnect</span> your email now.</p>
                                            <p>This is <span className={classes.textStrong}>recommended</span> action.
                                                Without it, your email will stop working, leading to the loss of key functionalities such as  <span
                                                    className={classes.textStrong}>sending, receiving, and event tracking</span>.</p>
                                            <p>Thank you for your understanding and continued trust in our service. We're confident that V3 will offer a more robust and efficient email experience.</p>
                                        </span>
                                        <input disabled={isDisabled} type="email" className={classes.emailInput}
                                            onChange={handleChangeInput} value={email}
                                            placeholder="Your personal email address" />
                                        {
                                            errorMessage &&
                                            <span className={classes.errorMessage}>{errorMessage}</span>
                                        }
                                        <div className={classes.actionContainer}>
                                            <span className={classes.actionButton} onClick={initConnectionProcess}>
                                                Reconnect
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                connectedFailedEmail &&
                                <div className={classes.footer}>
                                    <label className={classes.consent}>
                                        <input type="checkbox" className={classes.checkbox} checked={isDontShowAgain}
                                            onChange={(event) => {
                                                setIsDontShowAgain(event.target.checked);
                                            }} />
                                        Don't show this again for this email
                                    </label>
                                </div>
                            }
                        </Fragment>
                }
            </Paper>
        </Modal>
    );
};

export default NylasV3MigrationModal;
import React from "react";
import { IconButton } from "@material-ui/core";
import { ArrowRightIcon, UnreadMessageIcon } from "../common/Icons";
import SubTitle from "../common/SubTitle";
import Title from "../common/Title";
import { CardWrapper, GoButton } from "./index";

const UnreadMessageCard = ({ goToLink }) => {
  return (
    <CardWrapper className="UnreadMessageCard">
      <div>
        <div className="header">
          <Title text={"Unread Messages"} className="title" />

          <div className="actions">
            <div>
              <IconButton size="small">
                <UnreadMessageIcon />
              </IconButton>
            </div>
          </div>
        </div>
      </div>

      <div className="body">
        <div>
          <Title text={window.getUnreadMessageCount ? window.getUnreadMessageCount() : 0} className="value"/>
          <SubTitle text={"Messages"}/>
        </div>
        <GoButton
            variant="outlined"
            color="primary"
            endIcon={<ArrowRightIcon className="goIcon"/>}
            fullWidth
            onClick={() => goToLink('/inbox')}
        >
          Go
        </GoButton>
      </div>
    </CardWrapper>
  );
};

export default UnreadMessageCard;

import React from 'react';
import DailyGoalSkeleton from './DailyGoalSkeleton';
import MonthlyGoalSkeleton from './MonthlyGoalSkeleton';
import InsuranceCommonSkeleton from './InsuranceCommonSkeleton';
const InsuranceDashboardSkeleton = () => {
    
    return (
    <>
    <div style={{display:'flex',justifyContent:'space-between', alignItems:'center',gap:'20px'}}>
        <div style={{width:'65%'}}>
            <DailyGoalSkeleton/>
        </div>
        <div style={{width:'35%'}}>
            <MonthlyGoalSkeleton/>
        </div>
    </div>
    <div style={{width: '100%', display: 'flex', gap: '20px',marginTop:'12px'}}>
            <div style={{width:'25%',backgroundColor:'white',padding: "12px"}}>
                <InsuranceCommonSkeleton fromDashboard={true} />
            </div>
            <div style={{width:'25%',backgroundColor:'white',padding: "12px"}}>
                <InsuranceCommonSkeleton fromDashboard={true}/>
            </div>
            <div style={{width:'25%',backgroundColor:'white',padding: "12px"}}>
                <InsuranceCommonSkeleton fromDashboard={true}/>
            </div>
            <div style={{width:'25%',backgroundColor:'white',padding: "12px"}}>
                <InsuranceCommonSkeleton fromDashboard={true}/>
            </div>
            
        </div>
    </>
    );
};

export default InsuranceDashboardSkeleton;

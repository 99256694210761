import { List, ListItem, ListItemText, makeStyles } from "@material-ui/core";
import React from "react";
import Utils from "../../../helpers/Utils";
import { deleteCookie } from "../../../helpers/Cookie";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("md")]: {
      width: 230,
    },
    marginTop: "225px"
  },
  tabHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
  },
  tabTitle: {
    fontSize: 18,
    fontWeight: 500,
    margin: 0,
  },
  tabList: {
    "& .Mui-selected": {
      color: "#fff",
      backgroundColor: "#3E50F7",
      borderRadius: 4,
    },
  },
}));

const GetStartedTabs = () => {
  const classes = useStyles();

  const logout = (e) => {
    e.preventDefault();
    let userId = Utils.getAccountData("userId");

    deleteCookie(process.env.REACT_APP_ACCESS_TOKEN);
    deleteCookie(process.env.REACT_APP_ACCOUNT_DATA);
    if (localStorage.getItem("global__custom__field__data")) {
      localStorage.removeItem("global__custom__field__data");
    }
    // history.push('/login');
    const channel = new BroadcastChannel("pypepro-channel");
    
    // Send a message to other tabs
    channel.postMessage({ action: "reload-page-" + userId, link: "/" });
    window.open("/user/login", "_self");
  };
  return (
    <div className={`${classes.root} onboardingTabs`}>
      <div className={classes.tabHeader}>
        <h5 className={classes.tabTitle}>User Guides</h5>
        <img
          src="https://d24gujj67p1uc1.cloudfront.net/assets/images/releases/1.0.0/light-bulb.png"
          height={50}
          alt=""
        />
      </div>

      <List className={classes.tabList} dense>
        <ListItem selected button dense>
          <ListItemText primary="Getting Started" />
        </ListItem>
        <ListItem button dense>
          <ListItemText primary="Watch Overview Demo" />
        </ListItem>
        <ListItem button dense onClick={e => logout(e)}>
          <ListItemText primary="Logout" />
        </ListItem>
      </List>
    </div>
  );
};

export default GetStartedTabs;

import React, {useEffect, useState} from "react";
import { PieChart, Pie, Cell, Label, Text, Sector } from "recharts";
import {useSelector} from "react-redux";
import {selectInsuranceDashboardState} from "../../../reducers/Insurance-dashboard/insuranceDashboardSelector";
import { PIE_CHART_SLICE_SIZE } from "../../../constants/CoreConstants";
import { makeStyles } from "@material-ui/core";
import Utils from "../../../helpers/Utils";

const useStyles = makeStyles({
    dealStageChartContainer: {
        outline: "none !important",
        "& *:focus": {
          outline: "none !important",
        },
      },
    pieChart: {
      outline: 'none',
      '&:focus': {
        outline: 'none',
      },
    },
  });

const renderCustomLabel = ({ viewBox }, value) => {
    const { cx, cy } = viewBox;
    return (
        <>
            <Text
                x={cx}
                y={cy - 10}
                textAnchor="middle"
                dominantBaseline="middle"
                style={{ fontSize: "16px", fill: "#141928" }}
            >
                 Total Premium
            </Text>
            <Text
                x={cx}
                y={cy + 15}
                textAnchor="middle"
                dominantBaseline="middle"
                style={{ fontSize: "18px", fontWeight: "bold", fill: "#141928" }}
                
            >
                {Utils.formatCurrency(Utils.toZero(value), "$")}
            </Text>
        </>
    );
};
const renderPercentageLabel = ({
    cx, cy, midAngle, innerRadius, outerRadius, percent,index,color, onMouseEnter, onMouseLeave
}) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    const isLargeSlice = percent > PIE_CHART_SLICE_SIZE.SMALL_SLICE;
    const text = `${(percent * 100).toFixed(2)}%`;

    if (isLargeSlice) {
        return (
            <Text
                x={x}
                y={y}
                fill="#fff"
                textAnchor="middle"
                dominantBaseline="central"
                style={{ fontSize: "12px", fontWeight: "bold" }}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onmouseleave}
            >
                {text}
            </Text>
        );
    } else {
        const lineX = cx + (outerRadius + 15) * Math.cos(-midAngle * RADIAN);
        const lineY = cy + (outerRadius + 15) * Math.sin(-midAngle * RADIAN);

        const arrowX = cx + (outerRadius - 40) * Math.cos(-midAngle * RADIAN);
        const arrowY = cy + (outerRadius - 40) * Math.sin(-midAngle * RADIAN);

        const labelY = lineY + (lineY > cy ? 10 : -10);

        return (
            <>
                <line
                    x1={arrowX}
                    y1={arrowY}
                    x2={lineX}
                    y2={lineY}
                    stroke={color[index]}
                    strokeWidth={1}
                />
                <Text
                    x={lineX}
                    y={labelY}
                    fill="#000"
                    textAnchor={lineX > cx ? "start" : "end"}
                    dominantBaseline="central"
                    style={{ fontSize: "12px", fontWeight: "500" }}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onmouseleave}
                >
                    {text}
                </Text>
            </>
        );
    }
};

const STAGE_DUMMY_DATA = [
    {
      dealValue: 936,
      dealCount: 7,
      name: "New Leads",
      colorCode: "#3e50f7",
      value: 20
    },
    {
      dealValue: 1234,
      dealCount: 10,
      name: "Contacted",
      colorCode: "#f39c12",
      value: 20
    },
    {
      dealValue: 789,
      dealCount: 5,
      name: "Negotiation",
      colorCode: "#e74c3c",
      value: 20
    },
    {
      dealValue: 456,
      dealCount: 3,
      name: "Closed",
      colorCode: "#2ecc71",
      value: 20
    },
    {
      dealValue: 1123,
      dealCount: 9,
      name: "Qualified",
      colorCode: "#8e44ad",
      value: 20
    }
  ]
  

const BlurredPieChart = () => {
    const { stageInformation, stageInformationCount,stageInformationTotalValue } = useSelector(selectInsuranceDashboardState);
    const [chartData, setChartData] = useState([]);
    const [chartDataColor, setChartDataColor] = useState([]);
    const [activeIndex, setActiveIndex] = useState(null); 
    const classes = useStyles();
    const [largestSlice, setLargestSlice] = useState(null);

    useEffect(() => {
        if (stageInformation) {
            setChartDataColor(stageInformation.map((stage) => stage.colorCode));
            const formattedData = stageInformation.map((stage) => ({
                name: stage.stage,
                value: parseFloat(stage.percentage)
            }));
            setChartData(formattedData);

            let maxValue =0;
            let largeIndexes = [];
            formattedData.forEach((stage, idx) => {
              if (stage.value > maxValue) {
                maxValue = stage.value;
                largeIndexes = [idx]; 
              } else if (stage.value === maxValue) {
                largeIndexes.push(idx);
              }
            });
            
            setLargestSlice(largeIndexes);
            setActiveIndex(largeIndexes);
        }
    }, [stageInformation]);

    const onPieEnter = (_, index) => {
        setActiveIndex(index);
    };

    const onPieLeave = () => {
        setActiveIndex(largestSlice);
    };

    const renderActiveShape = (props) => {
        const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill, value } = props;
    
        return (
            <g>
                <Sector
                    cx={cx}
                    cy={cy}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius + 10}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    fill={fill}
                    filter="url(#hoverShadow)"
                />
                <Text
                    x={cx}
                    y={cy}
                    textAnchor="middle"
                    dominantBaseline="middle"
                    style={{ fontSize: "18px", fontWeight: "bold", fill: "#141928" }}
                >
                    {Utils.formatCurrency(Utils.toZero(value), "$")}
                </Text>
            </g>
        );
    };

    return (
        <div className={classes.dealStageChartContainer}>
            <svg width={494} height={494} style={{ position: "absolute", overflow: "visible" }}>
                <defs>
                    <filter id="hoverShadow" x="-50%" y="-50%" width="200%" height="200%">
                        <feDropShadow dx="0" dy="0" stdDeviation="20" floodColor="rgba(0, 0, 0, 0.2)" />
                    </filter>
                </defs>
                <defs>
                    <filter id="Shadow" x="-50%" y="-50%" width="200%" height="200%">
                        <feDropShadow dx="0" dy="0" stdDeviation="45" floodColor="rgba(0, 0, 0, 0.4)" />
                    </filter>
                </defs>
            </svg>
            
            <PieChart width={494} height={494}  style={{ outline: "none" }} >
                <Pie
                    data={STAGE_DUMMY_DATA}
                    dataKey="value"
                    cx="50%"
                    cy="50%"
                    innerRadius={125}
                    outerRadius={190}
                    activeIndex={activeIndex}
                    activeShape={renderActiveShape} 
                    onMouseEnter={onPieEnter}
                    onMouseLeave={onPieLeave}
                    startAngle={90}
                    endAngle={450}
                    paddingAngle={0}
                    label={(props) => renderPercentageLabel({...props, index: props.index, color: chartDataColor,
                        onMouseEnter: (e) => onPieEnter(e, props.index),
                        onMouseLeave : onPieLeave
                    })}
                    labelLine={false}
                    isInteractive={false}
                    className={classes.pieChart}                   
                >
                    {STAGE_DUMMY_DATA.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.colorCode}
                         />
                    ))}
                </Pie>

                <Pie
                    data={[{ name: "Total Invoices", value: 300 }]}
                    dataKey="value"
                    cx="50%"
                    cy="50%"
                    innerRadius={0}
                    outerRadius={95}
                    fill="#fff"
                    isAnimationActive={false}
                    isInteractive={false}
                    filter="url(#Shadow)"
                    className={classes.pieChart}
                >
                    <Label content={(content)=>renderCustomLabel(content, stageInformationTotalValue)} position="center" />
                </Pie>
            </PieChart>
        </div>
    );
};

export default BlurredPieChart;
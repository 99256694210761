import React from "react";
import { AchievedIcon, StarIcon, TargetIcon } from "../common/Icons";
import GoalItem from "./GoalItem";
import { styled } from "@material-ui/core";
import { useSelector } from "react-redux";
import { selectInsuranceDashboardState } from "../../../reducers/Insurance-dashboard/insuranceDashboardSelector";
import Utils from "../../../helpers/Utils";
import { GOAL_TYPE_SALES } from "../../../constants/CoreConstants";

const GoalListWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: 24,
}));

const GoalList = () => {
  const { goalStatFilter, startedTodayAt, shouldBeAt, goalForToday, selectedGoal } =
    useSelector(selectInsuranceDashboardState);

  const formatCurrency = (value) => {
    if (typeof value !== "number" || isNaN(value) || selectedGoal === null)
      return "N/A";

    if (goalStatFilter === GOAL_TYPE_SALES) {
      return "$" + Utils.abbreviateNumber(value) || "0.00";
    }
    return Utils.formatCurrency(Utils.toZero(value));
  };

  return (
    <GoalListWrapper className="goalList">
      <GoalItem
        text="STARTED TODAY AT"
        value={formatCurrency(startedTodayAt)}
        icon={<StarIcon />}
        bgColor={"#ECF4FF"}
      />
      <GoalItem
        text="SHOULD BE AT"
        value={formatCurrency(shouldBeAt)}
        icon={<TargetIcon />}
        bgColor={"#EEFFF5"}
      />
      <GoalItem
        text="GOAL FOR TODAY"
        value={formatCurrency(goalForToday)}
        icon={<AchievedIcon />}
        bgColor={"#F4F6FB"}
      />
    </GoalListWrapper>
  );
};

export default GoalList;

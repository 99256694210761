import React from "react";
import {Grid} from "@material-ui/core";
import Title from "../common/Title";
import SubTitle from "../common/SubTitle";
import {EachActivityWrapper} from "./index";
import { ChatOutlined } from "@material-ui/icons";

const formatTotalText = (totalText) => {
    try {
        if (totalText === undefined || totalText === null || isNaN(totalText)) {
            throw new Error("Invalid input");
        }

        totalText = Number(totalText);

        if (totalText >= 1000000) {
            return (totalText / 1000000).toFixed(2) + 'M';
        } else if (totalText >= 1000) {
            return (totalText / 1000).toFixed(2) + 'k';
        } else {
            return totalText;
        }
    } catch (error) {
        return "0";
    }
}

const Text = ({ activity }) => {
    return(
        <Grid container spacing={3}>
            <Grid item xs={12} lg={6}>
                <EachActivityWrapper bgColor={"#ECF4FF"}>
                <div className="goalIcon">
                    <ChatOutlined style={{color:"#611FB6"}} />
                    </div>

                    <div className="GoalText">
                        <Title text={formatTotalText(activity?.totalOutgoing ?? 0)} />
                        <SubTitle text={"Texts Sent"} className="subTitle" />
                    </div>
                </EachActivityWrapper>
            </Grid>
            <Grid item xs={12} lg={6}>
                <EachActivityWrapper bgColor={"#EEFFF5"}>
                <div className="goalIcon">
                    <ChatOutlined style={{color:"#611FB6"}} />
                    </div>
                    <div className="GoalText">
                        <Title text={formatTotalText(activity?.totalIncoming ?? 0)} />
                        <SubTitle text={"Texts Received"} className="subTitle" />
                    </div>
                </EachActivityWrapper>
            </Grid>
        </Grid>
    );
};

export default Text;
import { styled } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { selectInsuranceDashboardState } from "../../../../reducers/Insurance-dashboard/insuranceDashboardSelector";
import CustomChart from "./CustomChart";

const GoalChartWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "100%",

  "& .userSelect": {
    width: 330,
    marginLeft: "auto !important",
    marginRight: "14px !important",
  },

  "& .needleChartWrapper": {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 8,
  },
}));

const GoalChart = () => {
  const { goalForToday, achievedToday, stillNeededForToday } = useSelector(
    selectInsuranceDashboardState
  );

  return (
    <GoalChartWrapper className="goalChart">
      <div className="needleChartWrapper">
        <CustomChart
          maxValue={goalForToday}
          currentValue={achievedToday}
          remaining={stillNeededForToday}
        />
      </div>

      {/* <GoalItem
        text="ACHIEVED TODAY"
        value={"$23525"}
        icon={<TrophyIcon />}
        bgColor={"#FFFAF2"}
      /> */}
    </GoalChartWrapper>
  );
};

export default GoalChart;
